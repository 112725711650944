import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../../assets/css/login.css";
import { Helmet } from "react-helmet";
import logo from "../../assets/images/zimzee-logo.svg";
import EyeIcon from "../../assets/images/iconeye.svg";
import EyeIconShow from "../../assets/images/iconshow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullScreenLoader from "../../components/FullScreenLoader";
import configData from "../../utils/config";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordSeeOne, setPasswordSeeOne] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChangeSeePasswordOne = (e) => {
    e.preventDefault();
    setPasswordSeeOne(!passwordSeeOne);
  };
  useEffect(() => {
    if (password.length === 0) {
      setDisabled(true);
    }
    if (email.length === 0) {
      setEmailError("");
      setDisabled(true);
    } else if (
      /^(?!\s)(?=\S*$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      if (email.length > 0 && password.length > 0) {
        setDisabled(false);
      }
      setEmailError("");
    } else {
      setEmailError("Enter a valid email address");
      setDisabled(true);
    }
  }, [email, password]);
  const history = useHistory();
  const handleLoginClick = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const params = JSON.stringify({
      email: email.toLowerCase().trim(),
      password,
    });
    setLoading(true);
    await axios
      .post(`${configData.API_URL}/users/login`, params, config)
      .then((res) => {
        if (res.data?.data && res.data.data.user.role === "ADMIN") {
          console.log(res.data?.data);
          setPageLoading(true);
          localStorage.setItem("user", JSON.stringify(res.data?.data));
          // localStorage.setItem('user', JSON.stringify(res.data?.data))
          setTimeout(() => {
            history.push("/users");
          }, 3000);
        } else {
          toast.error("Invalid Credentials", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.message) {
          toast.error(err.response?.data?.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
          });
          return;
        }
        toast.error("Invalid credentials", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <>
      <Helmet>
        <title>Login | Zimzee</title>
      </Helmet>
      {pageLoading ? (
        <FullScreenLoader />
      ) : (
        <div className="auth-main-wrapper">
          <div className="Login">
            <div className="auth-inner-wrapper">
              <Form onSubmit={handleSubmit}>
                <div className="auth-logo-image">
                  <img src={logo} className="login-form-logo" />
                </div>
                <Form.Group size="lg" controlId="email">
                  <div className="site-input">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      autoFocus
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="text-danger mt-2">{emailError}</p>
                  </div>
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <div className="site-input">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={passwordSeeOne ? "text" : "password"}
                      className="type-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="eyes-icon"
                      onClick={(e) => onChangeSeePasswordOne(e)}
                    >
                      {passwordSeeOne ? (
                        <img src={EyeIconShow} alt="icon" />
                      ) : (
                        <img src={EyeIcon} alt="icon" />
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Button
                  block="true"
                  size="lg"
                  type="submit"
                  // disabled={disabled}
                  disabled={loading}
                  onClick={handleLoginClick}
                  className="login-btn"
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="spinner-border spinner-border-sm mr-2"></span>
                      <span>Logging in...</span>
                    </div>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
