// /* eslint-disable no-script-url */
// /* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react/jsx-no-target-blank */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { Fragment, useState, useEffect } from "react";
// import MainHeaderWithSidebar from "../../layouts/Header/MainHeader";
// import MobileHeader from "../../layouts/Header/MobileHeader";
// import FixedHeader from "../../layouts/Header/FixedHeader";
// import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
// import Select from "react-select";

// import { useDispatch } from "react-redux";
// import {
//   getHttp2,
//   putHttp2,
//   postHttp2,
//   removeHttp,
//   removeHttp2,
// } from "../../actions/config";
// import { SERVER_URI, BASE_URI_WEB } from "../../components/utils/config";
// import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import ToggleOnIcon from "@mui/icons-material/ToggleOn";
// import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
// import TextField from "@mui/material/TextField";
// import Swal from "sweetalert2";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ClearIcon from "@mui/icons-material/Clear";
// import SearchIcon from "@mui/icons-material/Search";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import Loader from "../../assets/images/loadergif.gif";
// import { Helmet } from "react-helmet";

// import { DelayInput } from "react-delay-input";
// import moment from "moment";
// const escapeRegExp = (value) => {
//   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
// };

// const QuickSearchToolbar = (props) => {
//   return (
//     <Box
//       sx={{
//         p: 0.5,
//         pb: 0,
//       }}
//     >
//       <TextField
//         variant="standard"
//         value={props.value}
//         onChange={props.onChange}
//         placeholder="Search…"
//         InputProps={{
//           startAdornment: <SearchIcon fontSize="small" />,
//           endAdornment: (
//             <IconButton
//               title="Clear"
//               aria-label="Clear"
//               size="small"
//               style={{ visibility: props.value ? "visible" : "hidden" }}
//               onClick={props.clearSearch}
//             >
//               <ClearIcon fontSize="small" />
//             </IconButton>
//           ),
//         }}
//         sx={{
//           width: {
//             xs: 1,
//             sm: "auto",
//           },
//           m: (theme) => theme.spacing(1, 0.5, 1.5),
//           "& .MuiSvgIcon-root": {
//             mr: 0.5,
//           },
//           "& .MuiInput-underline:before": {
//             borderBottom: 1,
//             borderColor: "divider",
//           },
//         }}
//       />
//       <GridToolbarContainer>
//         <GridToolbar printOptions={{ disableToolbarButton: true }} />
//       </GridToolbarContainer>
//     </Box>
//   );
// };

// QuickSearchToolbar.propTypes = {
//   clearSearch: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
// };

// const ApprovedOrders = () => {
//   const [variations, setVariations] = useState([]);
//   let history = useHistory();
//   const col = [
//     {
//       headerName: "First Name",
//       field: "first_name",
//       width: 200,
//       hideable: false,
//     },
//     {
//       headerName: "Last Name",
//       field: "last_name",
//       width: 200,
//       hideable: false,
//     },
//     {
//       headerName: "Graphic",
//       field: "email",
//       hideable: true,
//       width: 200,
//       renderCell: (data) =>
//         data.row.email !== "No Image" ? (
//           <img
//             src={`https://d2jgmcy51w050u.cloudfront.net/${data.row.email}`}
//             width={50}
//             height={50}
//           />
//         ) : (
//           <span class="text-danger">No Image</span>
//         ),
//       // renderCell: rowData => (<> {rowData.row.email}  </>)
//     },

//     {
//       headerName: "Product",
//       field: "custom_link_admin_view",
//       width: 200,
//       renderCell: (rowData) => rowData.row.title,
//     },
//     {
//       headerName: "Variations",
//       field: "created_at",
//       width: 300,
//       // renderCell: rowData=>rowData.row.variations?.formatted_name
//       renderCell: (rowData) => {
//         rowData.row.created_at ? (
//           <span class="mr-0 text-success">Approved</span>
//         ) : (
//           <span class="text-danger">Deleted</span>
//         );
//       },
//     },
//     //     {
//     //   field: 'details',
//     //   headerName: 'Details',
//     //   renderCell: (params) => (
//     //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//     //       <div>{params.value.name || "dsf"}</div>
//     //       <div style={{ marginLeft: 20 }}>
//     //         <DataGrid
//     //           rows={params.value.children}
//     //           columns={[
//     //             { field: 'id', headerName: 'ID', width: 90 },
//     //             { field: 'address', headerName: 'Address', width: 200 },
//     //             { field: 'phone', headerName: 'Phone', width: 150 },
//     //           ]}
//     //           autoHeight
//     //         />
//     //       </div>
//     //     </div>
//     //   ),
//     //   width: 400,
//     // },
//     // history.location.pathname === "/users-deleted" ? {
//     //   headerName: "Deleted Date",
//     //   field: "deleted_at",
//     //   width: 100
//     // } :
//     //   {
//     //     headerName: "Account Type",
//     //     field: "is_import",
//     //     width: 100,
//     //     renderCell: (rowData) =>
//     //       rowData.row.is_import === "Imported" ? (
//     //         <span class="mr-0 text-success">Imported</span>
//     //       ) : (
//     //         rowData.row.is_import === "Reactivated" ?  <span class="text-dark">Reactivated</span> :  <span class="text-dark"> {rowData.row.is_import === "Test" ? "Test" : "Default" }</span>
//     //       )
//     //   },
//     {
//       headerName: "Order No",
//       field: "id",
//       width: 100,
//     },
//     {
//       headerName: "Status",
//       field: "is_Active",
//       width: 100,

//       renderCell: (rowData) =>
//         rowData.row.is_Active === "approved" ? (
//           <span class="mr-0 text-success">Approved</span>
//         ) : (
//           <span class="text-danger">Deleted</span>
//         ),
//     },

//     // {
//     //   headerName: "Date Last Edited",
//     //   field: "updated_at",
//     //   width: 150,
//     //   // renderCell: rowData => moment(rowData.row.updated_at,"YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY")
//     // },
//     // {
//     //   headerName: "Date Profile Completed",
//     //   field: "profile_complete_date",
//     //   width: 150
//     // },
//     // {
//     //   headerName: "Views",
//     //   field: "views",
//     //   width: 100
//     // },
//     //     {
//     //       headerName: "Actions", field: "action", sortable: false,hideable: false,disableExport: true, width: 300, renderCell: (data) => (
//     //         <div className="d-flex text-center">
//     //           {
//     //             history.location.pathname === "/users-deleted" ? "" :
//     //               data.row.is_active === false || data.row.profile_complete_date === null || data.row.profile_complete_date === "" ||  data.row.is_import === false  ? <EditIcon className="cursor-pointer ml-4" /> : <span className="cursor-pointer">
//     //                 <EditIcon className="cursor-pointer ml-4" onClick={() => Updatehandler(data)} />
//     //               </span>

//     //           }
//     //           <span className="cursor-pointer">

//     //             <DeleteIcon className="cursor-pointer ml-4" onClick={() => DeleteHandler(data)} />
//     //           </span>

//     //           {
//     //             history.location.pathname === "/users-deleted" ?
//     //               <span className="cursor-pointer">
//     //                 {isActive === false ? <RefreshIcon className="cursor-pointer ml-4" onClick={

//     // (e) => restoreUser(e, data)

//     // } /> : <RefreshIcon className="cursor-pointer ml-4" onClick={

//     // (e) => restoreUser(e)

//     // } />}
//     //               </span>

//     //               :
//     //               <>

//     //                 {(data.row.is_profile_complete === false && data.row.is_import === "Reactivated" && data.row.is_active === false) || data.row.profile_complete_date === null || data.row.profile_complete_date === "" || data.row.is_Active === "Locked"  ? <ToggleOffIcon className="cursor-pointer ml-4" /> :

//     //                 <span
//     //                 className="cursor-pointer" onClick={() => isActives(data)}><ToggleOnIcon className="cursor-pointer ml-4" /></span>}

//     //                 {(data.row.is_profile_complete === false && data.row.is_import === "Reactivated" &&  data.row.is_active === false) || data.row.profile_complete_date === null || data.row.profile_complete_date === ""  ? <VisibilityIcon className="cursor-pointer ml-4" /> : <span className="cursor-pointer" onClick={() => visibilityViewUser(data)}><VisibilityIcon className="cursor-pointer ml-4" /></span>}

//     //                 <span className="cursor-pointer" onClick={() => seeProfile(data)}>
//     //                   {isActive === false ? <AccountBoxIcon className="cursor-pointer ml-4" /> : <AccountBoxIcon className="cursor-pointer ml-4" />}
//     //                 </span>

//     //               </>
//     //           }
//     //         </div>

//     //       )

//     //     },
//   ];
//   const [pageSize, setPageSize] = useState(10);

//   const [isLoading, setLoading] = useState(true);
//   const [disabled, setDisabled] = useState(false);
//   const [isActive, setisActive] = useState(false);
//   const [error, setError] = useState("");
//   const [selected, setSelected] = useState(null);
//   const [isProfileHomePage, setIsProfileHomePage] = useState(null);
//   const [isActiveProfile, setisActiveProfile] = useState(null);
//   const [isProfileCompleted, setisProfileCompleted] = useState(null);

//   const [isHomePageRank, setIsHomePageRank] = useState(null);

//   const [showErrorMessage, setShowErrorMessage] = useState("");

//   const [rows, setRows] = useState([]);
//   const [dataRows, setDataRows] = useState([]);
//   const [searchText, setSearchText] = useState("");
//   const [sortModel, setSortModel] = useState([{ field: "id", sort: "desc" }]);
//   const dispatch = useDispatch();
//   const [socialLoginHide, setSocialLoginHide] = useState(false);

//   const [columnVisibilityModel, setColumnVisibilityModel] = useState({
//     id: true,
//     first_name: true,
//     last_name: true,
//     email: true,
//     custom_link_admin_view: true,
//     deleted_at: true,
//     is_Active: true,
//     created_at: true,
//     updated_at: true,
//     profile_complete_date: true,
//     action: true,
//   });

//   const ranks = [
//     {
//       label: "1",
//       value: "1",
//     },
//     {
//       label: "2",
//       value: "2",
//     },
//     {
//       label: "3",
//       value: "3",
//     },
//   ];

//   const ranksNo = [
//     {
//       label: "No",
//       value: "No",
//     },
//     {
//       label: "1",
//       value: "1",
//     },
//     {
//       label: "2",
//       value: "2",
//     },
//     {
//       label: "3",
//       value: "3",
//     },
//   ];

//   const [user, setUser] = useState({
//     id: 0,
//     first_name: "",
//     last_name: "",
//     password: "",
//     role: "",
//     custom_profile_link: "",
//     is_profile_complete: false,
//   });
//   const [checkbox, setCheckbox] = useState(false);
//   const [isProfileCompletedFlag, setisProfileCompletedFlag] = useState(false);
//   const [isTestUserFlag, setisTestUserFlag] = useState(false);

//   const [userID, setUserID] = useState(null);

//   useEffect(() => {
//     setLoading(true);
//     users();
//   }, [history.location.pathname]);

//   const users = () => {
//     dispatch(getHttp2(`${SERVER_URI}api/v1/etsy/archieveOrder`))
//       .then((response) => {
//         let data = [];
//         response.data.data.forEach((element, index) => {
//           let object = {};
//           object.id = element.receipt_id;
//           object.first_name = element.user.firstName;
//           object.last_name = element.user.lastName;
//           object.email = element?.personalized_image_url
//             ? element?.personalized_image_url
//             : "No Image";
//           // object.custom_profile_link = element.is_profile_complete === true || element.is_import !== false ? element.custom_profile_link : "Profile not completed yet.";
//           // object.is_active = element.title;
//           // object.deleted_at = moment(element.deleted_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY");
//           object.created_at = element.variations.map((variant) => {
//             return `${variant.formatted_name} : ${variant.formatted_value}`;
//           });
//           //   return <span>{data.formatted_name}</span>
//           //  })}</div>`
//           //  object.details= {
//           // "name": 'Variations',
//           // "children": [
//           //   { "id": 1, "address": '123 Main St', "phone": '555-1234' },
//           //   { "id": 2, "address": '456 Oak Ave', "phone": '555-5678' },
//           // ],
//           // }
//           // let childeren= element.variations.map((variant)=>{

//           // return {"id":variant.formatted_name,"address":variant.formatted_value}
//           // })
//           // object.details.children=childeren
//           setVariations(element.variations);
//           // object.updated_at = element.updated_at && element.is_profile_complete !== false? moment(element.updated_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY") : 'None';
//           // object.profile_complete_date = element.profile_complete_date !== null ? element.profile_complete_date : "Completed Date Not Available";
//           // object.views = element.views;
//           // object.role = element.title;
//           // object.user_id = element.user.id;
//           // object.custom_link_with_domain = element.title;
//           object.custom_link_admin_view = element.title;
//           // object.update_custom_link = BASE_URI_WEB + "sp/" + element.custom_profile_link;
//           // object.is_home_page = element.is_home_page;
//           // object.is_profile_complete = element.is_profile_complete;
//           // object.home_page_rank = element.home_page_rank;
//           // object.is_import = element.is_import === true ? "Imported" : element.is_test_user === true ? "Test" : element.is_import === null ? "Reactivated" :  "Default";
//           // // object.is_Active = element.is_active === true ? "Active" : "InActive"
//           object.is_Active = element.queue_status;
//           // object.custom_profile_link_user = element.custom_profile_link;
//           // object.is_test_user = element.is_test_user;

//           data.push(object);
//         });
//         setDataRows(data);
//         setRows(data);

//         setLoading(false);
//       })
//       .catch(function (error) {
//         setLoading(false);
//       });
//   };

//   const userHomePage = (value, userID) => {
//     const data = {
//       rank: value,
//       user_profile_id: userID,
//     };
//     dispatch(postHttp2(`${SERVER_URI}api/home_page_profile/`, data))
//       .then((res) => {
//         if (JSON.parse(JSON.stringify(res)).status === 400) {
//           if (selected.value === null && selected.label === "Select Rank") {
//             setShowErrorMessage(
//               selected.value === null && selected.label === "Select Rank"
//                 ? ""
//                 : "Profiles for home page are already reached maximum limit"
//             );
//             onClickAddModalClose();
//           } else {
//             setShowErrorMessage(
//               "Profiles for home page are already reached maximum limit"
//             );
//           }
//         } else {
//           setShowErrorMessage("");
//           onClickAddModalClose();
//           users();
//         }
//       })
//       .catch((error) => {});
//   };

//   const onClickAddModalOpen = () => {
//     document.getElementById("kt_notes_panel").classList.add("offcanvas-on");
//   };
//   const onClickAddModalClose = () => {
//     setUser({
//       id: 0,
//       first_name: "",
//       last_name: "",
//       password: "",
//       role: "",
//       custom_profile_link: "",
//     });
//     setIsProfileHomePage(null);
//     setisActiveProfile(null);
//     setisProfileCompleted(null);
//     setShowErrorMessage("");

//     document.getElementById("kt_notes_panel").classList.remove("offcanvas-on");
//   };
//   const handleChange = (value) => {
//     setSelected(value);
//   };

//   const DeleteHandler = (data) => {
//     if (data.row.home_page_rank !== null && data.row.is_home_page !== null) {
//       Swal.fire({
//         position: "top-end",
//         icon: "warning",
//         title:
//           "This Profile is being used on the home page. Please remove it from there and try again",
//         showConfirmButton: false,
//       });
//     } else {
//       Swal.fire({
//         title: "Are you sure?",
//         text:
//           history.location.pathname === "/users-deleted"
//             ? "You won't be able to revert this!"
//             : "This user will be moved to Deleted User!",
//         icon: "warning",
//         showConfirmButton: true,
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!",
//         cancelButtonText: "Cancel",
//       }).then((result) => {
//         if (result.isConfirmed) {
//           dispatch(getHttp2(`${SERVER_URI}api/delete/user/${data.row.id}`))
//             .then((res) => {
//               Swal.fire("Deleted!", "User has been deleted.", "success");
//               let lists = [...rows].filter((x) => {
//                 return x.id !== data.id;
//               });

//               let listsRow = [...dataRows].filter((x) => {
//                 return x.id !== data.id;
//               });

//               setRows(lists);
//               setDataRows(listsRow);
//               setLoading(false);
//             })
//             .catch(function (error) {
//               setLoading(false);
//             });
//         }
//       });
//     }
//   };

//   const Updatehandler = (data) => {
//     onClickAddModalOpen();
//     setSocialLoginHide(data.row.is_social_login);
//     setIsProfileHomePage(data.row.is_home_page);
//     setisActiveProfile(data.row.is_active);
//     setisProfileCompleted(data.row.is_profile_complete);
//     setIsHomePageRank(data.row.home_page_rank);
//     setShowErrorMessage("");
//     setCheckbox(false);
//     setisProfileCompletedFlag(false);
//     setError("");
//     setUserID(data.row.id);
//     setisTestUserFlag(data.row.is_test_user);
//     const params = {
//       label:
//         data.row.home_page_rank === null
//           ? "Select Rank"
//           : data.row.home_page_rank,
//       value: data.row.home_page_rank,
//     };
//     setSelected(params);
//     setUser({
//       id: data.row.id,
//       role: data.row.role,
//       custom_profile_link: data.row.custom_profile_link_user,
//     });
//   };

//   const isActives = (data) => {
//     if (
//       data.row.home_page_rank !== null &&
//       data.row.is_home_page !== null &&
//       data.row.is_home_page !== undefined
//     ) {
//       Swal.fire({
//         position: "top-end",
//         icon: "warning",
//         title:
//           "This Profile is being used on the home page. Please remove it from there and try again",
//         showConfirmButton: false,
//       });
//     } else {
//       const bodyPro = {
//         id: data.row.id,
//         is_active: true,
//       };

//       if (data.row.is_active) {
//         Swal.fire({
//           title: "You want to Inactivate this User?",
//           text: "",
//           icon: "warning",
//           showConfirmButton: true,
//           showCancelButton: true,
//           confirmButtonColor: "#3085d6",
//           cancelButtonColor: "#d33",
//           confirmButtonText: "Yes",
//           cancelButtonText: "No",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             Swal.fire("Deactivated!", "User has been Inactivated.", "error");
//             setisActive(false);
//             bodyPro.is_active = false;
//             setUser(bodyPro);
//             // UserUpdatehandler(bodyPro)
//             getChangedStatus(bodyPro);
//           }
//         });
//       } else {
//         Swal.fire({
//           title: "You want to Activate this User?",
//           text: "",
//           icon: "warning",
//           showConfirmButton: true,
//           showCancelButton: true,
//           confirmButtonColor: "#3085d6",
//           cancelButtonColor: "#d33",
//           confirmButtonText: "Yes",
//           cancelButtonText: "No",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             Swal.fire("Activated!", "User has been Activated.", "success");
//             bodyPro.is_active = true;
//             setisActive(true);
//             setUser(bodyPro);
//             // UserUpdatehandler(bodyPro)
//             getChangedStatus(bodyPro);
//           }
//         });
//       }
//     }
//   };

//   const seeProfile = (data) => {
//     if (data.row.is_import === "Imported" || data.row.is_active === true) {
//       const win = window.open(`${data.row.custom_link_with_domain}`, "_blank");
//       win.focus();
//     }
//   };

//   const visibilityViewUser = (data) => {
//     history.push(
//       `/user/login-history/${data.row.user_id}/firstname=${data.row.first_name}&lastname=${data.row.last_name}`
//     );
//   };

//   const UserUpdatehandler = (bodyPro = null) => {
//     dispatch(putHttp2(`${SERVER_URI}api/profile/update/`, bodyPro))
//       .then((res) => {
//         let object = res.data.data;
//         const params = {
//           id: object.id,
//           first_name: object.first_name,
//           last_name: object.last_name,
//           email: object.user.email,
//           custom_profile_link:
//             object.is_profile_complete === true || object.is_import !== false
//               ? object.custom_profile_link
//               : object.custom_profile_link,
//           is_active: object.is_active,
//           deleted_at: moment(object.deleted_at, "YYYY-MM-DD HH:mm:ss").format(
//             "MM-DD-YYYY"
//           ),
//           created_at: moment(object.created_at, "YYYY-MM-DD HH:mm:ss").format(
//             "MM-DD-YYYY"
//           ),
//           updated_at:
//             object.updated_at && object.is_profile_complete !== false
//               ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format(
//                   "MM-DD-YYYY"
//                 )
//               : "None",
//           profile_complete_date:
//             object.profile_complete_date !== null
//               ? object.profile_complete_date
//               : "Completed Date Not Available",
//           views: object.views,
//           role: object.role,
//           user_id: object.user.id,
//           custom_link_with_domain: object.custom_link_with_domain,
//           is_home_page: object.is_home_page,
//           is_profile_complete: object.is_profile_complete,
//           home_page_rank: object.home_page_rank,
//           is_import:
//             object.is_import === true
//               ? "Imported"
//               : object.is_test_user === true
//               ? "Test"
//               : "Default",
//           // is_Active:object.is_active === true ? "Active" : "InActive"
//           is_Active: object.status,
//           is_test_user: object.is_test_user === true ? "Test" : "Default",
//         };
//         let existingItem = [...rows].findIndex(
//           (item) => item.id === res.data.data.id
//         );
//         let temp_array = [...rows];
//         temp_array[existingItem] = params;
//         setRows(temp_array);

//         let existingItems = dataRows.findIndex(
//           (item) => item.id === res.data.data.id
//         );
//         let temp_arrays = [...dataRows];
//         temp_arrays[existingItems] = params;
//         setDataRows(temp_arrays);
//         Swal.fire({
//           position: "top-end",
//           icon: "success",
//           title: "User updated successfully",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         onClickAddModalClose();
//       })
//       .catch((err) =>
//         Swal.fire({
//           position: "top-end",
//           icon: "error",
//           title: "Given Data was invalid!",
//           showConfirmButton: false,
//           timer: 1500,
//         })
//       );
//   };

//   const UserUpdatehandlerSubmit = (e) => {
//     e.preventDefault();
//     if (user.custom_profile_link !== "") {
//       setError("");
//       let bodyProo = {};
//       if (user.password !== "") {
//         bodyProo.id = user.id;
//         bodyProo.role = user.role;
//         bodyProo.custom_profile_link = user.custom_profile_link
//           ?.trim()
//           ?.toLocaleLowerCase();
//         bodyProo.is_test_user = isTestUserFlag;
//         if (checkbox === true) {
//           bodyProo.is_import = true;
//           bodyProo.is_profile_complete = false;
//         } else if (isProfileCompletedFlag === true) {
//           bodyProo.is_profile_complete = true;
//         }
//       } else {
//         bodyProo.id = user.id;
//         bodyProo.role = user.role;
//         bodyProo.custom_profile_link = user.custom_profile_link
//           ?.trim()
//           ?.toLocaleLowerCase();
//         bodyProo.is_test_user = isTestUserFlag;
//         if (checkbox === true) {
//           bodyProo.is_import = true;
//           bodyProo.is_profile_complete = false;
//         } else if (isProfileCompletedFlag === true) {
//           bodyProo.is_profile_complete = true;
//         }
//       }
//       if (selected.value === "No") {
//         onClickAddModalClose(e);
//         removeProfileHomePage();
//       } else {
//         if (isProfileCompleted === true) {
//           userHomePage(selected.value, user.id);
//         }
//       }
//       dispatch(putHttp2(`${SERVER_URI}api/profile/update/`, bodyProo))
//         .then((res) => {
//           let object = res.data.data;
//           if (Object.keys(res.data.data).length > 0) {
//             const params = {
//               id: object.id,
//               first_name: object.first_name,
//               last_name: object.last_name,
//               email: object.user.email,
//               custom_profile_link:
//                 object.is_profile_complete === true ||
//                 object.is_import !== false
//                   ? object.custom_profile_link
//                   : "Profile not completed yet.",
//               is_active: object.is_active,
//               deleted_at: moment(
//                 object.deleted_at,
//                 "YYYY-MM-DD HH:mm:ss"
//               ).format("MM-DD-YYYY"),
//               created_at: moment(
//                 object.created_at,
//                 "YYYY-MM-DD HH:mm:ss"
//               ).format("MM-DD-YYYY"),
//               updated_at:
//                 object.updated_at && object.is_profile_complete !== false
//                   ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format(
//                       "MM-DD-YYYY"
//                     )
//                   : "None",
//               profile_complete_date:
//                 object.profile_complete_date !== null
//                   ? object.profile_complete_date
//                   : "Completed Date Not Available",
//               views: object.views,
//               role: object.role,
//               user_id: object.user.id,
//               custom_link_with_domain: object.custom_link_with_domain,
//               is_home_page: object.is_home_page,
//               is_profile_complete: object.is_profile_complete,
//               home_page_rank: object.home_page_rank,
//               is_import:
//                 object.is_import === true
//                   ? "Imported"
//                   : object.is_test_user === true
//                   ? "Test"
//                   : object.is_import === null
//                   ? "Reactivated"
//                   : "Default",
//               is_Active: object.status,
//               is_test_user: object.is_test_user,
//               custom_profile_link_user: object.custom_profile_link,
//               status: object.status,
//               custom_link_admin_view:
//                 object.is_profile_complete === true ||
//                 object.is_import !== false
//                   ? BASE_URI_WEB + "sp/" + object.custom_profile_link
//                   : object.status === "In Active" ||
//                     object.status === "Verified" ||
//                     object.status === "Started Onboarding"
//                   ? "Profile not completed yet."
//                   : "Profile not completed yet.",
//             };
//             Swal.fire({
//               position: "top-end",
//               icon: "success",
//               title: "User updated successfully",
//               showConfirmButton: false,
//               timer: 1500,
//             });
//             let existingItem = [...rows].findIndex(
//               (item) => item.id === res.data.data.id
//             );
//             let temp_array = [...rows];
//             temp_array[existingItem] = params;
//             setRows(temp_array);

//             let existingItems = dataRows.findIndex(
//               (item) => item.id === res.data.data.id
//             );
//             let temp_arrays = [...dataRows];
//             temp_arrays[existingItems] = params;
//             setDataRows(temp_arrays);
//             onClickAddModalClose(e);
//             // if(isTestUserFlag){
//             //   getChangedStatus()
//             // }
//             setisTestUserFlag(object.is_test_user);
//           }
//         })
//         .catch(
//           (err) => {
//             onClickAddModalClose(e);
//           }
//           // Swal.fire({
//           //   position: "top-end",
//           //   icon: "error",
//           //   title: "Given Data was invalid!",
//           //   showConfirmButton: false,
//           //   timer: 1500,
//           // })
//         );
//     } else {
//       setError("Profile Link can't be empty");
//     }
//   };

//   const restoreUser = (e, data) => {
//     setLoading(true);
//     dispatch(removeHttp2(`${SERVER_URI}api/restore/user/${data.id}/`))
//       .then((res) => {
//         setLoading(false);
//         Swal.fire({
//           position: "top-end",
//           icon: "success",
//           title: "User Restored successfully",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         let lists = [...rows].filter((x) => {
//           return x.id !== data.id;
//         });

//         let listsRow = [...dataRows].filter((x) => {
//           return x.id !== data.id;
//         });

//         setRows(lists);
//         setDataRows(listsRow);
//       })
//       .catch((err) => {
//         onClickAddModalClose(e);
//       });
//   };

//   const getChangedStatus = (params) => {
//     dispatch(putHttp2(`${SERVER_URI}api/change/status/`, params))
//       .then((res) => {
//         let object = res.data.data;
//         const params = {
//           id: object.id,
//           first_name: object.first_name,
//           last_name: object.last_name,
//           email: object.user.email,
//           custom_profile_link:
//             object.is_profile_complete === true || object.is_import !== false
//               ? object.custom_profile_link
//               : object.custom_profile_link,
//           is_active: object.is_active,
//           deleted_at: moment(object.deleted_at, "YYYY-MM-DD HH:mm:ss").format(
//             "MM-DD-YYYY"
//           ),
//           created_at: moment(object.created_at, "YYYY-MM-DD HH:mm:ss").format(
//             "MM-DD-YYYY"
//           ),
//           updated_at:
//             object.updated_at && object.is_profile_complete !== false
//               ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format(
//                   "MM-DD-YYYY"
//                 )
//               : "None",
//           profile_complete_date:
//             object.profile_complete_date !== null
//               ? object.profile_complete_date
//               : "Completed Date Not Available",
//           views: object.views,
//           role: object.role,
//           user_id: object.user.id,
//           custom_link_with_domain: object.custom_link_with_domain,
//           is_home_page: object.is_home_page,
//           is_profile_complete: object.is_profile_complete,
//           home_page_rank: object.home_page_rank,
//           is_import:
//             object.is_import === true
//               ? "Imported"
//               : object.is_test_user === true
//               ? "Test"
//               : object.is_import === null
//               ? "Reactivated"
//               : "Default",
//           // is_Active:object.is_active === true ? "Active" : "InActive"        // is_Active:object.is_active === true ? "Active" : "InActive"
//           is_Active: object.status,
//           is_test_user: object.is_test_user,
//           custom_profile_link_user: object.custom_profile_link,
//           status: object.status,
//           custom_link_admin_view:
//             object.is_profile_complete === true || object.is_import !== false
//               ? BASE_URI_WEB + "sp/" + object.custom_profile_link
//               : object.status === "In Active" ||
//                 object.status === "Verified" ||
//                 object.status === "Started Onboarding"
//               ? "Profile not completed yet."
//               : "Profile not completed yet.",
//         };
//         let existingItem = [...rows].findIndex(
//           (item) => item.id === res.data.data.id
//         );
//         let temp_array = [...rows];
//         temp_array[existingItem] = params;
//         setRows(temp_array);

//         let existingItems = dataRows.findIndex(
//           (item) => item.id === res.data.data.id
//         );
//         let temp_arrays = [...dataRows];
//         temp_arrays[existingItems] = params;
//         setDataRows(temp_arrays);
//       })
//       .catch((err) => {});
//   };

//   const isImportChange = (event) => {
//     setCheckbox(event.target.checked);
//   };

//   const isTestUserStatus = (event) => {
//     setisTestUserFlag(event.target.checked);
//   };

//   const isProfileCompletedStatus = (event) => {
//     setisProfileCompletedFlag(event.target.checked);
//   };

//   const onChangeUserFields = (event) => {
//     const { name, value } = event.target;
//     if (name === "custom_profile_link") {
//       const profileUrl = {
//         profile_custom_url: value.toLocaleLowerCase(),
//         id: user.id,
//       };
//       dispatch(postHttp2(`${SERVER_URI}api/check/profile/url/`, profileUrl))
//         .then((res) => {
//           if (res.data.message === false) {
//             setDisabled(true);
//             setError("Profile Link Already Exist");
//           } else {
//             setDisabled(false);
//             setError("");
//           }
//         })
//         .catch((err) => {});
//     }
//     setUser((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const removeProfileHomePage = () => {
//     dispatch(
//       removeHttp(`${SERVER_URI}api/home_page_profile/${isProfileHomePage}`)
//     ).then(() => {
//       onClickAddModalClose();
//       users();
//       document
//         .getElementById("kt_notes_panel")
//         .classList.remove("offcanvas-on");
//     });
//   };

//   const onChangeRole = (e) => {
//     setUser((prevState) => ({ ...prevState, role: e.target.value }));
//   };
//   const handleSortModelChange = (newModel) => {
//     setSortModel(newModel);
//   };
//   const requestSearch = (searchValue) => {
//     setSearchText(searchValue);
//     const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
//     const filteredRows = dataRows.filter((row) => {
//       return Object.keys(row).some((field) => {
//         return searchRegex.test(JSON.stringify(row[field]));
//       });
//     });
//     setRows(filteredRows);
//   };

//   const handleColumnVisibilityChange = (col) => {
//     setColumnVisibilityModel(col);
//   };

//   const Datatable = () => {
//     return (
//       <div className="">
//         <div style={{ width: "100%", maxWidth: "100%" }}>
//           <div style={{ display: "flex", height: "100%" }}>
//             <div style={{ flexGrow: 1 }}>
//               <DataGrid
//                 autoHeight
//                 // initialState={{
//                 //   sorting: {
//                 //     sortModel: [{ field: 'id', sort: 'asec' }],
//                 //   },
//                 // }}
//                 rows={rows}
//                 columns={col}
//                 rowsPerPageOptions={[5, 10, 25, 50, 100]}
//                 // sortModel={sortModel}
//                 onSortModelChange={handleSortModelChange}
//                 onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//                 pageSize={pageSize}
//                 pagination
//                 components={{ Toolbar: QuickSearchToolbar }}
//                 getRowClassName={(params) =>
//                   `MuiDataGrid-row-${params.row.home_page_rank}`
//                 }
//                 disableSelectionOnClick
//                 // disableColumnMenu
//                 // disableColumnFilter
//                 // disableColumnSelector
//                 columnVisibilityModel={columnVisibilityModel}
//                 onColumnVisibilityModelChange={(newModel) =>
//                   handleColumnVisibilityChange(newModel)
//                 }
//                 componentsProps={{
//                   toolbar: {
//                     value: searchText,
//                     onChange: (event) => requestSearch(event.target.value),
//                     clearSearch: () => requestSearch(""),
//                   },
//                 }}
//                 // autoGroupColumnDef={{
//                 //   field: 'details',
//                 //   headerName: 'Details',
//                 //   hide: true,
//                 // }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   const Spinner = () => {
//     return (
//       <div className="custom-loader">
//         <div className="pre-loader">
//           <img className="img-fluid" src={Loader} alt="loading" />
//         </div>
//       </div>
//     );
//   };
//   return (
//     <Fragment>
//       <Helmet>
//         <title>Approved Orders | HelloCustom</title>
//       </Helmet>
//       <div
//         id="tc_body"
//         className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
//       >
//         {/*begin::Header Mobile*/}
//         <MobileHeader />
//         {/*end::Header Mobile*/}
//         {/*begin::Main*/}
//         <div className="d-flex flex-column flex-root">
//           {/*begin::Page*/}
//           <div className="d-flex flex-row flex-column-fluid page">
//             <MainHeaderWithSidebar />
//             {/*end::Aside*/}
//             {/*begin::Wrapper*/}
//             <div
//               className="d-flex flex-column flex-row-fluid wrapper"
//               id="tc_wrapper"
//             >
//               {/*begin::Header*/}
//               <FixedHeader />
//               {/*end::Header*/}
//               {/*begin::Content*/}
//               <div
//                 className="content d-flex flex-column flex-column-fluid"
//                 id="tc_content"
//               >
//                 {/*begin::Subheader*/}
//                 {/* <div className="subheader py-2 py-lg-6 subheader-solid">
//                   <div className="container-fluid">
//                     <nav aria-label="breadcrumb">
//                       <ol className="breadcrumb bg-white mb-0 px-0 py-2">
//                         <li
//                           className="breadcrumb-item active"
//                           aria-current="page"
//                         >
//                           <a href="/dashboard">Dashboard</a>
//                         </li>
//                       </ol>
//                     </nav>
//                   </div>
//                 </div> */}
//                 {/*end::Subheader*/}
//                 {/*begin::Entry*/}
//                 <div className="d-flex flex-column-fluid" id="asdddd">
//                   {/*begin::Container*/}
//                   <div className="container-fluid">
//                     <div className="row">
//                       <div className="col-12">
//                         <div className="row">
//                           <div className="col-lg-12 col-xl-12">
//                             <div className="card card-custom gutter-b bg-transparent shadow-none border-0">
//                               <div className="card-header align-items-center  border-bottom-dark p-0">
//                                 <div className="card-title mb-0">
//                                   <h3 className="card-label mb-0 font-weight-bold text-body">
//                                     {history.location.pathname ===
//                                     "/users-deleted"
//                                       ? "Deleted Users"
//                                       : "Approved Orders"}
//                                   </h3>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="row">
//                           <div className="col-12 ">
//                             <div className="card card-custom gutter-b bg-white border-0">
//                               <div className="card-body">
//                                 {isLoading === true ? (
//                                   <Spinner></Spinner>
//                                 ) : (
//                                   Datatable()
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/*end::Footer*/}
//             </div>
//             {/*end::Wrapper*/}
//           </div>
//           {/*end::Page*/}
//         </div>
//         {/*end::Main*/}

//         <div
//           id="kt_notes_panel"
//           className="offcanvas offcanvas-right kt-color-panel p-5"
//         >
//           <div className="offcanvas-header d-flex align-items-center justify-content-between pb-3">
//             <h4 className="font-size-h4 font-weight-bold m-0">Edit User</h4>
//             <a
//               href="javascript:void(0);"
//               className="btn btn-sm btn-icon btn-light btn-hover-primary"
//               id="kt_notes_panel_close"
//               onClick={(e) => onClickAddModalClose()}
//             >
//               <svg
//                 width="20px"
//                 height="20px"
//                 viewBox="0 0 16 16"
//                 className="bi bi-x"
//                 fill="currentColor"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
//                 />
//               </svg>
//             </a>
//           </div>
//           <form id="myform">
//             <div className="row">
//               <div className="col-12">
//                 <div className="form-group">
//                   <label className="text-dark" htmlFor="exampleInputEmail1">
//                     Role
//                   </label>

//                   <select
//                     className="arabic-select w-100 mb-3"
//                     name="role"
//                     onChange={(e) => onChangeRole(e)}
//                   >
//                     <option value="" disabled selected>
//                       Select Role
//                     </option>
//                     <option
//                       value="U"
//                       selected={user.role === "U" ? true : false}
//                     >
//                       User
//                     </option>
//                     <option
//                       value="A"
//                       selected={user.role === "A" ? true : false}
//                     >
//                       Admin
//                     </option>
//                   </select>
//                 </div>
//                 {socialLoginHide !== true ? (
//                   <div className="form-group">
//                     <label className="text-dark cursor-pointer">
//                       <input
//                         type="checkbox"
//                         className="mr-3"
//                         checked={checkbox}
//                         onChange={(e) => isImportChange(e)}
//                       />
//                       Make this Account Imported
//                     </label>
//                   </div>
//                 ) : (
//                   ""
//                 )}

//                 <div className="form-group">
//                   <label className="text-dark cursor-pointer">
//                     <input
//                       type="checkbox"
//                       className="mr-3"
//                       checked={isProfileCompletedFlag}
//                       onChange={(e) => isProfileCompletedStatus(e)}
//                     />
//                     Make this Profile Completed
//                   </label>
//                 </div>
//                 <div className="form-group">
//                   <label className="text-dark cursor-pointer">
//                     <input
//                       type="checkbox"
//                       className="mr-3"
//                       checked={isTestUserFlag}
//                       onChange={(e) => isTestUserStatus(e)}
//                     />
//                     Test User
//                   </label>
//                 </div>
//                 <div className="form-group">
//                   <label className="text-dark">Profile Link</label>
//                   <DelayInput
//                     type="text"
//                     className="form-control"
//                     name="custom_profile_link"
//                     value={user.custom_profile_link?.toLocaleLowerCase()}
//                     onChange={(e) => onChangeUserFields(e)}
//                     placeholder="Profile Link"
//                   />
//                   <p style={{ color: "red" }}>{error}</p>
//                 </div>

//                 <div className="d-flex">
//                   {isActiveProfile === true && isProfileCompleted === true ? (
//                     <>
//                       <label htmlFor="" className="mt-3 mr-4">
//                         {" "}
//                         Show on Home Page ?
//                       </label>
//                       <Select
//                         className="custom-form-control mb-2 ml-3"
//                         placeholder="Select Ranks"
//                         name="ranks"
//                         selected={selected}
//                         onChange={(e) => handleChange(e)}
//                         options={isHomePageRank === null ? ranks : ranksNo}
//                         value={selected}
//                       />
//                     </>
//                   ) : (
//                     ""
//                   )}
//                 </div>
//                 <p style={{ color: "red" }}>{showErrorMessage}</p>
//               </div>

//               {/* </Tab.Container> */}
//               {/* </div> */}
//             </div>
//             {history.location.pathname === "/users-deleted" ? (
//               ""
//             ) : (
//               <button
//                 type="submit"
//                 disabled={disabled}
//                 className="btn btn-primary"
//                 onClick={(e) => UserUpdatehandlerSubmit(e)}
//               >
//                 Edit User
//               </button>
//             )}
//           </form>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default React.memo(ApprovedOrders);

import { Helmet } from "react-helmet";
import MobileHeader from "../../layouts/Header/MobileHeader";
import MainHeaderWithSidebar from "../../layouts/Header/MainHeader";
import FixedHeader from "../../layouts/Header/FixedHeader";
import { useState } from "react";
import request from "../../utils/request";
import { useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import config from "../../utils/config";

const ApprovedOrderNew = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [numOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [totalUser, setTotalUser] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [show, setShow] = useState(false);
  const [btnLoadingStatus, setBtnLoadingStatus] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (user) => {
    setShow(true);
    setCurrentUser(user);
  };

  useEffect(() => {
    if (!show) {
      setCurrentUser(null);
    }
  }, [show]);
  /**
        Function Name: dateFormate
        @return DateFormate in 03/04/2023
    */
  const dateFormate = (date) => {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY");
  };

  /**
        Function Name: getAllUsers
        @params limit,currentPage,sort
        @return results,totalPages, page, totalRecords
    */
  const getAllUsers = async (cb, page) => {
    try {
      setLoading(true);
      const response = await request.get(
        `api/v1/etsy/archieveOrder?&limit=${limit}&page=${
          page || currentPage
        }&text=${search}`
      );
      setUsers(response?.data?.data?.results || []);
      setNumberOfPages(response?.data?.data?.totalPages || 0);
      setCurrentPage(response?.data?.data?.page || 1);
      setTotalUser(response?.data?.data?.totalRecords || 0);
      if (cb) {
        cb();
      }
    } catch (error) {
      if (error?.response) {
        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page) => {
    return new Promise((resolve, reject) => {
      setCurrentPage(page);
      resolve(page);
    });
  };
  const handlePageClick = (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    handlePaginationChange(currentPage).then((page) => {
      //   console.log("page", currentPage);
      getAllUsers(null, page);
    });
  };

  const changeUserStatus = async () => {
    let body = {
      id: currentUser._id,
      is_active: currentUser.status === "active" ? false : true,
    };

    try {
      setBtnLoadingStatus(true);
      const response = await request.get(
        `api/v1/admin/users/${!body.is_active ? "deactive" : "active"}/${
          body.id
        }`
      );

      getAllUsers(handleClose);
    } catch (e) {
    } finally {
      getAllUsers(handleClose);
      setBtnLoadingStatus(false);
    }
  };

  const handleSearchChange = (text) => {
    return new Promise((resolve, reject) => {
      setNumberOfPages(0);
      setCurrentPage(1);
      setSearch(text);
      resolve();
    });
  };
  const handleSerch = (e) => {
    handleSearchChange(e.target.value).then(() => {
      // getAllUsers();
    });
    console.log("run");
  };

  useEffect(() => {
    getAllUsers();
  }, [search]);
  return (
    <>
      <Helmet>
        <title>Approved Orders | HelloCustom</title>
      </Helmet>

      <div
        id="tc_body"
        className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
      >
        <MobileHeader />
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <MainHeaderWithSidebar />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="tc_wrapper"
            >
              <FixedHeader />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="tc_content"
              >
                <div className="d-flex flex-column-fluid" id="asdddd">
                  {/*begin::Container*/}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="card card-custom gutter-b bg-transparent shadow-none border-0">
                              <div className="card-header align-items-center  border-bottom-dark p-0">
                                <div className="card-title mb-0">
                                  <h3 className="card-label mb-0 font-weight-bold text-body">
                                    {history.location.pathname ===
                                    "/users-deleted"
                                      ? "Deleted Users"
                                      : "Approved Orders"}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <>
                          <div className="row">
                            <div className="col-12 ">
                              <div className="custom-table-wrapper">
                                <div className="table-data-results">
                                  <h5>
                                    {totalUser}{" "}
                                    {totalUser.length > 0
                                      ? "results"
                                      : "result"}
                                  </h5>
                                  <div className="table-search">
                                    <div className="form-wrapper">
                                      <input
                                        type="text"
                                        placeholder="Search by order..."
                                        value={search}
                                        onChange={(e) => handleSerch(e)}
                                      />
                                      <i className="fas fa-search"></i>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`custom-table-inner ${
                                    users.length !== 0 && "active"
                                  }`}
                                >
                                  {loading ? (
                                    <div class="custom-loader-wrapper section-loader">
                                      <svg
                                        class="custom-loading-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="323.528"
                                        height="323.843"
                                        viewBox="0 0 323.528 323.843"
                                      >
                                        <g
                                          id="Group_1"
                                          data-name="Group 1"
                                          transform="translate(-3372.604 -815.725)"
                                        >
                                          <path
                                            id="Path_1"
                                            data-name="Path 1"
                                            d="M3372.6,977.8c-.009-89.462,72.536-162.15,161.752-162.073,89.35.077,161.884,72.772,161.776,162.135-.107,89.477-72.708,161.908-162.087,161.708A161.741,161.741,0,0,1,3372.6,977.8Zm162.145-91.847a91.669,91.669,0,0,0-92.022,91.235c-.244,50.261,40.469,92,89.979,92.25,51.765.259,93.2-40.331,93.367-91.461A91.88,91.88,0,0,0,3534.749,885.951Z"
                                            fill="#c0b0f6"
                                          />
                                          <path
                                            id="Path_2"
                                            data-name="Path 2"
                                            d="M4037.018,1099.117l-.1-69.481c55.334-4.517,83.932-34.587,91.917-91.844h69.268C4200.638,1019.806,4130.373,1099.822,4037.018,1099.117Z"
                                            transform="translate(-502.414 39.933)"
                                            fill="#ee7900"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>First Name</th>
                                          <th>Last Name</th>
                                          <th>Graphic</th>
                                          <th>Product</th>
                                          <th>Variations</th>
                                          <th>Order No</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {users.length == 0 && (
                                          <tr>
                                            <td colSpan="9">
                                              <p className="text-danger text-center">
                                                No record found
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {users.map((user, index) => (
                                          <tr key={index}>
                                            <td>
                                              {user?.user?.firstName || "N/A"}
                                            </td>
                                            <td>
                                              {user?.user?.lastName || "N/A"}
                                            </td>
                                            <td>
                                              <img
                                                src={`${config.s3BucketUrl}/${user?.personalized_image_url}`}
                                                width={50}
                                                height={50}
                                                alt="personalized_image_url"
                                              />
                                            </td>
                                            <td>{user?.title || "N/A"}</td>
                                            <td>
                                              {user.variations.map(
                                                (variation, index) => (
                                                  <div key={index}>
                                                    <p>
                                                      {
                                                        variation?.formatted_name
                                                      }
                                                      :{" "}
                                                      {variation?.formatted_value ||
                                                        "N/A"}
                                                    </p>
                                                  </div>
                                                )
                                              )}
                                            </td>
                                            <td>{user?.receipt_id || "N/A"}</td>
                                            <td className="text-capitalize text-bold">
                                              {user?.queue_status ===
                                              "deleted" ? (
                                                <p className="text-danger">
                                                  {user?.queue_status}
                                                </p>
                                              ) : user?.queue_status ===
                                                "approved" ? (
                                                <p className="text-success">
                                                  {user?.queue_status}
                                                </p>
                                              ) : (
                                                "N/A"
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>

                                {users.length !== 0 && numOfPages > 1 && (
                                  <ReactPaginate
                                    pageCount={numOfPages} // Total Pages
                                    pageRange={currentPage} // Current Page
                                    // initialPage={currentPage - 1} // page initail value -1
                                    marginPagesDisplayed={2} // Pages before and after current page
                                    onPageChange={handlePageClick} // Function to call when page clicked
                                    containerClassName={"pagination-listing"} // Class Name for pagination UL
                                    previousLinkClassName={"page"} // Class for previous page
                                    breakClassName={"page"} // Class for break
                                    nextLinkClassName={"page"} // Class for next page
                                    pageClassName={"page"} // Class for single pagination link
                                    disabledClassName={"disabled"} // Class for disabled navigation link
                                    activeClassName={"active"} // Class for active navigation link
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                        {currentUser && (
                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                          >
                            <Modal.Body>
                              <pre>{JSON.stringify(currentUser?.status)}</pre>
                              {}
                              Are you sure? you want to{" "}
                              {currentUser?.status === "active"
                                ? "Deactive"
                                : "Active"}{" "}
                              this user
                            </Modal.Body>

                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                className="btn-gray"
                                size="sm"
                                onClick={handleClose}
                                disabled={btnLoadingStatus}
                              >
                                No
                              </Button>
                              {btnLoadingStatus ? (
                                <Button
                                  size="sm"
                                  variant="primary"
                                  disabled={true}
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                    <span>Loading...</span>
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={changeUserStatus}
                                >
                                  Yes
                                </Button>
                              )}
                            </Modal.Footer>
                          </Modal>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*end::Footer*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}
      </div>
    </>
  );
};

export default ApprovedOrderNew;
