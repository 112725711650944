/*
  ! @Author: Muddusar Zulfiqar
  ! @Last Modified by: Muddusar Zulfiqar
  * Description: This file contains the request file for the application.
*/
import axios from "axios";
import { toast } from "react-toastify";
import config from "./config";
// import Cookies from "js-cookie";

const source = axios.CancelToken.source();

const request = axios.create({
  baseURL: config.API_URL,
  timeout: 15000,
  cancleToken: source.token,
});

request.interceptors.request.use(
  (config) => {
    config.headers={Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`}
    // NProgress.start();
    // disable all elements on page while request is in progress
    const body = document.querySelector("body");
    body.classList.add("loading-indicator");
    return config;
  },
  (error) => {
    // NProgress.done();
    // check if error is section timeout
    const body = document.querySelector("body");
    body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // NProgress.done();
    // enable all elements on page after request is completed
    const body = document.querySelector("body");
    body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    const body = document.querySelector("body");
    body.classList.remove("loading-indicator");
    // NProgress.done();
    if (error?.response?.status === 401) {
      // logout user from store
      toast.error("Session Expired", {
        position: "bottom-right",
        autoClose: 3000,
      });
      localStorage.clear();

      localStorage.removeItem("user");
      localStorage.removeItem("userinfo");

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (error?.response?.status === 403) {
      // toast.error("You are not authorized to access this page", {
      //   position: "bottom-right",
      //   autoClose: 3000,
      // });
      localStorage.clear();

      localStorage.removeItem("user");
      localStorage.removeItem("userinfo");
      //   router.push("/login");
    } else if (error?.response?.status === 500) {
      toast.error("Internal Server Error", {
        position: "bottom-right",
        autoClose: 3000,
      });
    } else if (error?.response?.status === 404) {
      toast.error("Not Found", {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
    return Promise.reject(error);
  }
);

export default request;

// ? Path: src/utils/request.js
