import React , {useState,useEffect}from 'react'
import Loader from '../../assets/images/loadergif.gif'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { SERVER_URI } from "../../components/utils/config";

const Verify = (props) => {
   const [laoder, setLoader] = useState(true)
    const history = useHistory();
    


   useEffect(() => {
       let user = {
           token: props.match.params.token
       }

      axios.get(`${SERVER_URI}api/users/profile/detail/`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin" : "*",
          "Authorization": "JWT " + user.token
        
        }
      })
       .then((res) => {
            if(res.data.data.role === "A"){
              localStorage.setItem('user', JSON.stringify(user))
              history.push('/dashboard')
            }else{
               history.goBack();
            }
           })
           .catch((err) => 
           history.goBack()
           );

      
   }, [])


   return (
    <div className="custom-loader">
    <div className="pre-loader">
      <img
        className="img-fluid"
        src={Loader}
        alt="loading"
      />
    </div>
    </div>
   )
}

export default Verify
