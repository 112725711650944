import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { TOGGLE_SIDEBAR } from "../../../actions/types";
import { Dropdown } from "react-bootstrap";
import { BASE_URI, BASE_URI_WEB } from "../../../components/utils/config";
import { useHistory } from "react-router-dom";

const FixedHeader = () => {

  const dispatch = useDispatch();
  //for language excahnger
   const history = useHistory();
   
  const [user, setUser] = useState({});

  useEffect(() => {
    let usr = JSON.parse(localStorage.getItem("user"));
    if (usr !== null) {
      setUser(usr);
    }
  }, []);

  const onClickH = () => {
    $("body").toggleClass("aside-minimize");
    dispatch({
      type: TOGGLE_SIDEBAR,
    });
  };

  //logout function
  const onClickback = () => {
   
    localStorage.removeItem("user");
    history.push('/')
    // dispatch(logout(token));
    // Cookies.remove("token");
    // window.location.assign(`${BASE_URI_WEB}basic-control`);
    
  };


  return (
    <Fragment>
      <div id="tc_header" className="header header-fixed">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/*begin::Header Menu Wrapper*/}
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="tc_header_menu_wrapper"
          >
            {/*begin::Header Menu*/}
            <div
              id="tc_header_menu"
              className="header-menu header-menu-mobile header-menu-layout-default"
            >
              {/*begin::Header Nav*/}
              <ul className="menu-nav">
                <li
                  className="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active"
                  data-menu-toggle="click"
                  aria-haspopup="true"
                >
                 
                  {/*end::Toolbar*/}
                </li>
              </ul>
              {/*end::Header Nav*/}
            </div>
            {/*end::Header Menu*/}
          </div>
          {/*end::Header Menu Wrapper*/}
          {/*begin::Topbar*/}
          <div className="topbar">
          
            <div className="dropdown">
              <div
                className="topbar-item"
                data-toggle="dropdown"
                data-offset="10px,0px"
              >
               
              </div>
            </div>
            <div style={{ width: "20px" }}></div>
            <Dropdown>
              <Dropdown.Toggle
                className="topbar-item shadows l"
                drop="right"
                variant=""
              >
                <div className="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
                  <span className="text-dark-50 font-size-base d-none d-md-inline mr-3">
                    {user.user !== undefined ? user.user.name : "Hi user"}
                  </span>
                  <span className="symbol symbol-35 symbol-light-success">
                    <span className="symbol-label font-size-h5 ">
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 16 16"
                        className="bi bi-person-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
               
                <Dropdown.Item onClick={() => onClickback()}>
                  <span className="svg-icon svg-icon-xl svg-icon-primary mr-2">
                  <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 16 16"
                        className="bi bi-person-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                  </span>
                 Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/*end::user*/}
          </div>
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>

      {/* for response header small screen */}
    </Fragment>
  );
};

export default FixedHeader;
