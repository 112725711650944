import React, { Fragment, useEffect, useState } from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/zimzee-logo.svg";
import { Link, useLocation } from "react-router-dom";
const MainHeaderWithSidebar = () => {
  const toggle_sidebar = useSelector((state) => state.general.toggle_sidebar);
  let location = useLocation();
  let pathname = location.pathname;
  useEffect(() => {
    pathname = location.pathname;
  }, [location]);

  return (
    <Fragment>
      <div className="aside test">
        <ProSidebar collapsed={toggle_sidebar} breakPoint="md">
          <SidebarHeader>
            <div className="brand flex-column-auto" id="tc_brand">
              {/*begin::Logo*/}
              <Link to="/dashboard" className="brand-logo">
                <img
                  className="brand-image"
                  style={{ height: 25 }}
                  alt="Logo"
                  src={logo}
                />
                <span className="brand-text">
                  <img style={{ height: 25 }} alt="Logo" src={logo} />
                </span>
              </Link>
              {/*end::Logo*/}
            </div>
          </SidebarHeader>
          <SidebarContent className="scroll-maunal">
            <Menu>
              {/* <MenuItem
                className={`${pathname.includes("dashboard") ? "active" : ""}`}
                icon={
                  <span className="svg-icon nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                      <polyline points="9 22 9 12 15 12 15 22" />
                    </svg>
                  </span>
                }
              >
                <Link to="/dashboard">
                  <span className="nav-text fontt">Dashboard</span>
                </Link>
              </MenuItem> */}
              <MenuItem
                className={`${pathname.includes("users") ? "active" : ""}`}
                icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/users">
                  <span className="nav-text fontt">Users</span>
                </Link>
              </MenuItem>
              {/* <MenuItem
                className={`${
                  pathname.includes("approved-orders") ? "active" : ""
                }`}
                icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/approved-orders">
                  <span className="nav-text fontt">
                    Approved list of Orders
                  </span>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                className={`${pathname.match('/subscriptions-plans') ? 'active' : ''}`}
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/subscriptions-plans">
                    <span className="nav-text fontt">Subscriptions Plans Management</span>
                </Link>
              </MenuItem> */}

              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/icons">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Icons</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-quote-right font-size-h4" />
                  </span>
                }
              >
                <Link to="/tags">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Tags</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-quote-right font-size-h4" />
                  </span>
                }
              >
                <Link to="/sections">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Sections</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-chart-line font-size-h4" />
                  </span>
                }
              >
                <Link to="/sections">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Sections</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-clipboard-check font-size-h4" />
                  </span>
                }
              >
                <Link  to="/user-history">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Users Login History</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                  icon={
                    <span className="svg-icon nav-icon">
                      <i className="fas fa-user-friends font-size-h4" />
                    </span>
                  }
              >
                <Link to="/user-views">
                  <a className="nav-item active">
                    <span className="nav-text fontt">User Views</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/users-deleted">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Deleted Users</span>
                  </a>
                </Link>
              </MenuItem> */}
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/groups">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Groups</span>
                  </a>
                </Link>
                
              </MenuItem> */}
              {/* <hr />
               <h4 className="text-center"> <strong> CMS </strong></h4>
               <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/a-surprising-fact-about-me">
                  <a className="nav-item active">
                    <span className="nav-text fontt">A Surprising Fact About Me</span>
                  </a>
                </Link>
                
              </MenuItem>
              <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/what-im-up-to">
                  <a className="nav-item active">
                    <span className="nav-text fontt">What I’m Up To</span>
                  </a>
                </Link>
                
              </MenuItem>
              <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <Link to="/a-little-about-me">
                  <a className="nav-item active">
                    <span className="nav-text fontt">A Little Bit About Me</span>
                  </a>
                </Link>
                
              </MenuItem> */}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </Fragment>
  );
};
export default MainHeaderWithSidebar;
