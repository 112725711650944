import { combineReducers } from 'redux';

 import general from './general';
 
export default combineReducers({

 
 general,
 

});
