import axios from "axios";
import request from "../utils/request";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

// Post Request
export const postHttp = (url, body) => async (dispatch) => {
  const returnObject = {};
  try {
    // const res = await axios.post(url, body, config);
    const res = await axios.post(url, body, config);
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// Get Request
export const getHttp = (url, body) => async (dispatch) => {
  const returnObject = {};
  try {
    const res = await axios.get(url, config);
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// remove Request
export const removeHttp = (url, body) => async (dispatch) => {
  const returnObject = {};
  try {
    const res = await axios.delete(url, config);
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// Post Request
export const postHttp2 = (url, body) => async (dispatch) => {
  const returnObject = {};
  const user = JSON.parse(localStorage.getItem("user"));
  try {
    const res = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "JWT " + user.token,
      },
    });
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// Get Request
export const getHttp2 = (url, body) => async (dispatch) => {
  const returnObject = {};
  const user = JSON.parse(localStorage.getItem("user"));

  try {
    const res = await request.get(url);
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// Get Request
export const putHttp2 = (url, body) => async (dispatch) => {
  const returnObject = {};
  const user = JSON.parse(localStorage.getItem("user"));

  try {
    const res = await axios.put(url, body, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "JWT " + user.token,
      },
    });
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};

// remove Request
export const removeHttp2 = (url, body) => async (dispatch) => {
  const returnObject = {};
  const user = JSON.parse(localStorage.getItem("user"));

  try {
    const res = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "JWT " + user.token,
      },
    });
    returnObject.success = res.data.success;
    returnObject.data = res.data;
    return returnObject;
  } catch (err) {
    return err;
  }
};
