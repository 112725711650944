import React , {useState,useEffect}from 'react'

const AccessDeni = (props) => {
  


   return (
   <div>
       <h1>Access Denied </h1>
       <h2>Please login  by click <a href="https://v2.sparrows.co/login">here</a> </h2>
   </div>
   )
}

export default AccessDeni
