import { Helmet } from "react-helmet";
import MobileHeader from "../../layouts/Header/MobileHeader";
import MainHeaderWithSidebar from "../../layouts/Header/MainHeader";
import FixedHeader from "../../layouts/Header/FixedHeader";
import { useState } from "react";
import request from "../../utils/request";
import { useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { Button, Modal } from "react-bootstrap";
import config from "../../utils/config";

const ApprovedOrderNew = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [numOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalUser, setTotalUser] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [show, setShow] = useState(false);
  const [btnLoadingStatus, setBtnLoadingStatus] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (user) => {
    setShow(true);
    setCurrentUser(user);
  };

  useEffect(() => {
    if (!show) {
      setCurrentUser(null);
    }
  }, [show]);
  /**
        Function Name: dateFormate
        @return DateFormate in 03/04/2023
    */
  const dateFormate = (date) => {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY");
  };

  /**
        Function Name: getAllUsers
        @params limit,currentPage,sort
        @return results,totalPages, page, totalRecords
    */
  const getAllUsers = async (cb, page) => {
    try {
      setLoading(true);
      const response = await request.get(
        `api/v1/etsy/archieveOrder?sort=1&limit=${limit}&page=${
          page || currentPage
        }&search=${search}`
      );
      console.log(response?.data);
      setUsers(response?.data?.data?.results || []);
      setNumberOfPages(response?.data?.data?.totalPages || 0);
      setCurrentPage(response?.data?.data?.page || 1);
      setTotalUser(response?.data?.data?.totalRecords || 0);
      if (cb) {
        cb();
      }
    } catch (error) {
      if (error?.response) {
        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page) => {
    return new Promise((resolve, reject) => {
      setCurrentPage(page);
      resolve(page);
    });
  };
  const handlePageClick = (data) => {
    console.log(data.selected);
    let currentPage = data.selected + 1;
    handlePaginationChange(currentPage).then((page) => {
      //   console.log("page", currentPage);
      getAllUsers(null, page);
    });
  };

  const changeUserStatus = async () => {
    let body = {
      id: currentUser._id,
      is_active: currentUser.status === "active" ? false : true,
    };

    try {
      setBtnLoadingStatus(true);
      const response = await request.get(
        `api/v1/admin/users/${!body.is_active ? "deactive" : "active"}/${
          body.id
        }`
      );

      getAllUsers(handleClose);
    } catch (e) {
    } finally {
      getAllUsers(handleClose);
      setBtnLoadingStatus(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleSearchChange = (text) => {
    return new Promise((resolve, reject) => {
      setNumberOfPages(0);
      setCurrentPage(1);
      resolve();
    });
  };
  const handleSerch = (e) => {
    setSearch(e.target.value);
    handleSearchChange().then(() => {
      getAllUsers();
    });
    console.log("run");
  };

  return (
    <>
      <Helmet>
        <title>Users | HelloCustom</title>
      </Helmet>

      <div
        id="tc_body"
        className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
      >
        <MobileHeader />
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <MainHeaderWithSidebar />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="tc_wrapper"
            >
              <FixedHeader />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="tc_content"
              >
                <div className="d-flex flex-column-fluid" id="asdddd">
                  {/*begin::Container*/}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="card card-custom gutter-b bg-transparent shadow-none border-0">
                              <div className="card-header align-items-center  border-bottom-dark p-0">
                                <div className="card-title mb-0">
                                  <h3 className="card-label mb-0 font-weight-bold text-body">
                                    User
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <>
                          <div className="row">
                            <div className="col-12 ">
                              <div className="custom-table-wrapper">
                                <div className="table-data-results">
                                  <h5>
                                    {totalUser}{" "}
                                    {totalUser.length > 0
                                      ? "results"
                                      : "result"}
                                  </h5>
                                  <div className="table-search">
                                    <div className="form-wrapper">
                                      <input
                                        type="text"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e) => handleSerch(e)}
                                      />
                                      <i className="fas fa-search"></i>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`custom-table-inner ${
                                    users.length !== 0 && "active"
                                  }`}
                                >
                                  {loading ? (
                                    <div class="custom-loader-wrapper section-loader">
                                      <svg
                                        class="custom-loading-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="323.528"
                                        height="323.843"
                                        viewBox="0 0 323.528 323.843"
                                      >
                                        <g
                                          id="Group_1"
                                          data-name="Group 1"
                                          transform="translate(-3372.604 -815.725)"
                                        >
                                          <path
                                            id="Path_1"
                                            data-name="Path 1"
                                            d="M3372.6,977.8c-.009-89.462,72.536-162.15,161.752-162.073,89.35.077,161.884,72.772,161.776,162.135-.107,89.477-72.708,161.908-162.087,161.708A161.741,161.741,0,0,1,3372.6,977.8Zm162.145-91.847a91.669,91.669,0,0,0-92.022,91.235c-.244,50.261,40.469,92,89.979,92.25,51.765.259,93.2-40.331,93.367-91.461A91.88,91.88,0,0,0,3534.749,885.951Z"
                                            fill="#c0b0f6"
                                          />
                                          <path
                                            id="Path_2"
                                            data-name="Path 2"
                                            d="M4037.018,1099.117l-.1-69.481c55.334-4.517,83.932-34.587,91.917-91.844h69.268C4200.638,1019.806,4130.373,1099.822,4037.018,1099.117Z"
                                            transform="translate(-502.414 39.933)"
                                            fill="#ee7900"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>First Name</th>
                                          <th>Last Name</th>
                                          <th>Graphic</th>
                                          <th>Product</th>
                                          <th>Variations</th>
                                          <th>Order No</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {users.length == 0 && (
                                          <tr>
                                            <td colSpan="9">
                                              <p className="text-danger text-center">
                                                No record found
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {users.map((user, index) => (
                                          <tr key={index}>
                                            <td>
                                              {user?.user?.firstName || "N/A"}
                                            </td>
                                            <td>
                                              {user?.user?.lastName || "N/A"}
                                            </td>
                                            <td>
                                              <img
                                                src={`${config.s3BucketUrl}/${user?.personalized_image_url}`}
                                                width={50}
                                                height={50}
                                                alt="per_image"
                                              />
                                            </td>
                                            <td>{user?.title || "N/A"}</td>
                                            <td>
                                              {user.variations.map(
                                                (variation, index) => (
                                                  <div key={index}>
                                                    <p>
                                                      {
                                                        variation?.formatted_name
                                                      }
                                                      :{" "}
                                                      {variation?.formatted_value ||
                                                        "N/A"}
                                                    </p>
                                                  </div>
                                                )
                                              )}
                                            </td>
                                            <td>{user?.receipt_id || "N/A"}</td>
                                            <td className="text-capitalize text-bold">
                                              {user?.queue_status ===
                                              "deleted" ? (
                                                <p className="text-danger">
                                                  {user?.queue_status}
                                                </p>
                                              ) : user?.queue_status ===
                                                "approved" ? (
                                                <p className="text-success">
                                                  {user?.queue_status}
                                                </p>
                                              ) : (
                                                "N/A"
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  )}
                                </div>

                                {users.length !== 0 && numOfPages > 1 && (
                                  <ReactPaginate
                                    pageCount={numOfPages} // Total Pages
                                    pageRange={currentPage} // Current Page
                                    // initialPage={currentPage - 1} // page initail value -1
                                    marginPagesDisplayed={2} // Pages before and after current page
                                    onPageChange={handlePageClick} // Function to call when page clicked
                                    containerClassName={"pagination-listing"} // Class Name for pagination UL
                                    previousLinkClassName={"page"} // Class for previous page
                                    breakClassName={"page"} // Class for break
                                    nextLinkClassName={"page"} // Class for next page
                                    pageClassName={"page"} // Class for single pagination link
                                    disabledClassName={"disabled"} // Class for disabled navigation link
                                    activeClassName={"active"} // Class for active navigation link
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                        {currentUser && (
                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                          >
                            <Modal.Body>
                              <pre>{JSON.stringify(currentUser?.status)}</pre>
                              {}
                              Are you sure? you want to{" "}
                              {currentUser?.status === "active"
                                ? "Deactive"
                                : "Active"}{" "}
                              this user
                            </Modal.Body>

                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                className="btn-gray"
                                size="sm"
                                onClick={handleClose}
                                disabled={btnLoadingStatus}
                              >
                                No
                              </Button>
                              {btnLoadingStatus ? (
                                <Button
                                  size="sm"
                                  variant="primary"
                                  disabled={true}
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                    <span>Loading...</span>
                                  </div>
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  variant="primary"
                                  onClick={changeUserStatus}
                                >
                                  Yes
                                </Button>
                              )}
                            </Modal.Footer>
                          </Modal>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*end::Footer*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}
      </div>
    </>
  );
};

export default ApprovedOrderNew;
