/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import MainHeaderWithSidebar from "../../layouts/Header/MainHeader";
import MobileHeader from "../../layouts/Header/MobileHeader";
import FixedHeader from "../../layouts/Header/FixedHeader";
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbarContainer,GridToolbar  } from '@mui/x-data-grid';
import Select from "react-select";
import {Helmet} from "react-helmet";

import { useDispatch } from "react-redux";
import {
  getHttp2,
  putHttp2,
  postHttp2,
  removeHttp,
  removeHttp2
} from "../../actions/config";
import { SERVER_URI, BASE_URI_WEB } from "../../components/utils/config";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import Loader from '../../assets/images/loadergif.gif'

import { DelayInput } from "react-delay-input";
import moment from "moment";
const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const QuickSearchToolbar = (props) => {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
      <GridToolbarContainer>
        <GridToolbar printOptions={{ disableToolbarButton: true }}/>
      </GridToolbarContainer>
    </Box>
  );
};

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const SubscriptionsPlans = () => {
  let history = useHistory();
  const col = [
    {
      headerName: "ID",
      field: "id",
      width: 100,
    },
    { headerName: "Plan Name", field: "first_name", width: 200,hideable: false
  },
    // { headerName: "Last Name", field: "last_name", width: 200,hideable: false },
    {
      headerName: "Plan Description",
      field: "email",
      hideable: false,
      width: 300,
      renderCell: rowData => (<> {rowData.row.email}  </>)

    },
//     {
//       headerName: "URL",
//       field: "custom_link_admin_view",
//       width: 300,
//       renderCell: (rowData) => (<> {rowData.row.is_profile_complete === true || rowData.row.is_import !== false ? rowData.row.custom_link_admin_view === "Profile not completed yet." ? " Profile not completed yet." : <a href={rowData.row.custom_link_admin_view + "?admin=view"} target="_blank" >  {rowData.row.custom_link_admin_view} </a> : <> <span> {rowData.row.is_Active === "In Active" || rowData.row.is_Active === "Verified" || rowData.row.is_Active === "Started Onboarding"  ? "Profile not completed yet." : "Profile not completed yet."}  </span> </>}</>)

//     },
//     history.location.pathname === "/users-deleted" ? {
//       headerName: "Deleted Date",
//       field: "deleted_at",
//       width: 100
//     } :
//       {
//         headerName: "Account Type",
//         field: "is_import",
//         width: 100,
//         renderCell: (rowData) =>
//           rowData.row.is_import === "Imported" ? (
//             <span class="mr-0 text-success">Imported</span>
//           ) : (
//             rowData.row.is_import === "Reactivated" ?  <span class="text-dark">Reactivated</span> :  <span class="text-dark"> {rowData.row.is_import === "Test" ? "Test" : "Default" }</span>
//           )
//       },
//     {
//       headerName: "Status",
//       field: "is_Active",
//       width: 100,
//       // renderCell: (rowData) =>
//       //   rowData.row.is_active === true ? (
//       //     setisActive(rowData.row.is_active),
//       //     <span class="mr-0 text-success">Active</span>
//       //   ) : (
//       //     <span class="text-danger">InActive</span>
//       //   ),
//     },
//     {
//       headerName: "Date Created",
//       field: "created_at",
//       width: 100,
//       // renderCell: rowData => moment(rowData.row.created_at,"YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY")
//     },
//     {
//       headerName: "Date Last Edited",
//       field: "updated_at",
//       width: 150,
//       // renderCell: rowData => moment(rowData.row.updated_at,"YYYY-MM-DD HH:mm:ss").format("MM/DD/YYYY")
//     },
//     {
//       headerName: "Date Profile Completed",
//       field: "profile_complete_date",
//       width: 150
//     },
//     {
//       headerName: "Views",
//       field: "views",
//       width: 100
//     },
//     {
//       headerName: "Actions", field: "action", sortable: false,hideable: false,disableExport: true, width: 300, renderCell: (data) => (
//         <div className="d-flex text-center">
//           {
//             history.location.pathname === "/users-deleted" ? "" :
//               data.row.is_active === false || data.row.profile_complete_date === null || data.row.profile_complete_date === "" ||  data.row.is_import === false  ? <EditIcon className="cursor-pointer ml-4" /> : <span className="cursor-pointer">
//                 <EditIcon className="cursor-pointer ml-4" onClick={() => Updatehandler(data)} />
//               </span>


//           }
//           <span className="cursor-pointer">

//             <DeleteIcon className="cursor-pointer ml-4" onClick={() => DeleteHandler(data)} />
//           </span>

//           {
//             history.location.pathname === "/users-deleted" ?
//               <span className="cursor-pointer">
//                 {isActive === false ? <RefreshIcon className="cursor-pointer ml-4" onClick={

// (e) => restoreUser(e, data)

// } /> : <RefreshIcon className="cursor-pointer ml-4" onClick={

// (e) => restoreUser(e)

// } />}
//               </span>

//               :
//               <>




//                 {(data.row.is_profile_complete === false && data.row.is_import === "Reactivated" && data.row.is_active === false) || data.row.profile_complete_date === null || data.row.profile_complete_date === "" || data.row.is_Active === "Locked"  ? <ToggleOffIcon className="cursor-pointer ml-4" /> :
                
//                 <span 
//                 className="cursor-pointer" onClick={() => isActives(data)}><ToggleOnIcon className="cursor-pointer ml-4" /></span>}


//                 {(data.row.is_profile_complete === false && data.row.is_import === "Reactivated" &&  data.row.is_active === false) || data.row.profile_complete_date === null || data.row.profile_complete_date === ""  ? <VisibilityIcon className="cursor-pointer ml-4" /> : <span className="cursor-pointer" onClick={() => visibilityViewUser(data)}><VisibilityIcon className="cursor-pointer ml-4" /></span>}

//                 <span className="cursor-pointer" onClick={() => seeProfile(data)}>
//                   {isActive === false ? <AccountBoxIcon className="cursor-pointer ml-4" /> : <AccountBoxIcon className="cursor-pointer ml-4" />}
//                 </span>

//               </>
//           }
//         </div>

//       )

//     },

  ];
  const [pageSize, setPageSize] = useState(10);

  const [isLoading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [error, setError] = useState("");
  const [selected, setSelected] = useState(null);
  const [isProfileHomePage, setIsProfileHomePage] = useState(null);
  const [isActiveProfile, setisActiveProfile] = useState(null);
  const [isProfileCompleted, setisProfileCompleted] = useState(null);

  const [isHomePageRank, setIsHomePageRank] = useState(null);

  const [showErrorMessage, setShowErrorMessage] = useState("");

  const [rows, setRows] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortModel, setSortModel] = useState([
    { field: 'id', sort: 'desc' },
  ]);
  const dispatch = useDispatch();
  const [socialLoginHide, setSocialLoginHide] = useState(false);


  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: true,
    first_name: true,
    last_name: true,
    email: true,
    custom_link_admin_view: true,
    deleted_at: true,
    is_Active: true,
    created_at: true,
    updated_at: true,
    profile_complete_date: true,
    action: true,
  });


  const ranks = [
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    }
  ]

  const ranksNo = [
    {
      label: "No",
      value: "No"
    },
    {
      label: "1",
      value: "1"
    },
    {
      label: "2",
      value: "2"
    },
    {
      label: "3",
      value: "3"
    }
  ]

  const [user, setUser] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    password: "",
    role: "",
    custom_profile_link: "",
    is_profile_complete: false,
  });
  const [checkbox, setCheckbox] = useState(false);
  const [isProfileCompletedFlag, setisProfileCompletedFlag] = useState(false);
  const [isTestUserFlag, setisTestUserFlag] = useState(false);

  const [userID, setUserID] = useState(null);

  useEffect(() => {
    setLoading(true);
    users();
  }, [history.location.pathname]);

  const users = () => {
    dispatch(
      getHttp2(
        `${SERVER_URI}api/v1/plan/`
      )
    )
      .then((response) => {
        let data = [];
        response.data.data.forEach((element, index) => {
          let object = {};
          object.id = element._id;
          object.first_name = element.name;
          // object.last_name = element.last_name;
          object.email = element.description;
          // object.custom_profile_link = element.
          // object.is_active = element.is_active;
          // object.deleted_at = moment(element.deleted_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY");
          // object.created_at = moment(element.created_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY");
          // object.updated_at = element.updated_at && element.is_profile_complete !== false? moment(element.updated_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY") : 'None';
          // object.profile_complete_date = element.profile_complete_date !== null ? element.profile_complete_date : "Completed Date Not Available";
          // object.views = element.views;
          // object.role = element.role;
          // object.user_id = element.user.id;
          // object.custom_link_with_domain = element.custom_link_with_domain;
          // object.custom_link_admin_view =  element.is_profile_complete === true || element.is_import !== false ?  BASE_URI_WEB + "sp/" + element.custom_profile_link : element.is_Active === "In Active" || element.is_Active === "Verified" || element.is_Active === "Started Onboarding" ? "Profile not completed yet." : "Profile not completed yet.";
          // object.update_custom_link = BASE_URI_WEB + "sp/" + element.custom_profile_link;
          // object.is_home_page = element.is_home_page;
          // object.is_profile_complete = element.is_profile_complete;
          // object.home_page_rank = element.home_page_rank;
          // object.is_import = element.is_import === true ? "Imported" : element.is_test_user === true ? "Test" : element.is_import === null ? "Reactivated" :  "Default";
          // // object.is_Active = element.is_active === true ? "Active" : "InActive"
          // object.is_Active = element.status;
          // object.custom_profile_link_user = element.custom_profile_link;
          // object.is_test_user = element.is_test_user;


          data.push(object)
        });
        setDataRows(data)
        setRows(data);

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  }


  const userHomePage = (value, userID) => {
    const data =
    {
      rank: value,
      user_profile_id: userID
    }
    dispatch(
      postHttp2(
        `${SERVER_URI}api/home_page_profile/`, data
      )
    ).then((res) => {
      if (JSON.parse(JSON.stringify(res)).status === 400) {
        if (selected.value === null && selected.label === "Select Rank") {
          setShowErrorMessage(selected.value === null && selected.label === "Select Rank" ? "" : "Profiles for home page are already reached maximum limit")
          onClickAddModalClose();
        } else {
          setShowErrorMessage("Profiles for home page are already reached maximum limit")
        }
      } else {
        setShowErrorMessage("");
        onClickAddModalClose();
        users()
      }

    }).catch((error) => {
    });
  }



  const onClickAddModalOpen = () => {
    document.getElementById("kt_notes_panel").classList.add("offcanvas-on")

  };
  const onClickAddModalClose = () => {
    setUser({
      id: 0,
      first_name: "",
      last_name: "",
      password: '',
      role: "",
      custom_profile_link: ""
    });
    setIsProfileHomePage(null);
    setisActiveProfile(null)
    setisProfileCompleted(null)
    setShowErrorMessage("")

    document.getElementById("kt_notes_panel").classList.remove("offcanvas-on")
  };
  const handleChange = value => {
    setSelected(value);
  };


  const DeleteHandler = (data) => {

    if (data.row.home_page_rank !== null && data.row.is_home_page !== null) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "This Profile is being used on the home page. Please remove it from there and try again",
        showConfirmButton: false,
      })
    } else {
      Swal.fire({
        title: "Are you sure?",
        text:  history.location.pathname === "/users-deleted" ? "You won't be able to revert this!" : "This user will be moved to Deleted User!",
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(getHttp2(`${SERVER_URI}api/delete/user/${data.row.id}`))
            .then((res) => {
              Swal.fire("Deleted!", "User has been deleted.", "success");
              let lists = [...rows].filter(x => {
                return x.id !== data.id;
              })

              let listsRow = [...dataRows].filter(x => {
                return x.id !== data.id;
              })

              setRows(lists)
              setDataRows(listsRow)
              setLoading(false);
            })
            .catch(function (error) {
              setLoading(false);
            });
        }
      });
    }

  };

  const Updatehandler = (data) => {
    onClickAddModalOpen();
    setSocialLoginHide(data.row.is_social_login)
    setIsProfileHomePage(data.row.is_home_page);
    setisActiveProfile(data.row.is_active)
    setisProfileCompleted(data.row.is_profile_complete);
    setIsHomePageRank(data.row.home_page_rank)
    setShowErrorMessage("");
    setCheckbox(false);
    setisProfileCompletedFlag(false)
    setError("");
    setUserID(data.row.id)
    setisTestUserFlag(data.row.is_test_user)
    const params = {
      label: data.row.home_page_rank === null ? "Select Rank" : data.row.home_page_rank,
      value: data.row.home_page_rank
    }
    setSelected(params)
    setUser({
      id: data.row.id,
      role: data.row.role,
      custom_profile_link: data.row.custom_profile_link_user
    });
  };

  const isActives = (data) => {
    if ((data.row.home_page_rank !== null && data.row.is_home_page !== null) && data.row.is_home_page !== undefined) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "This Profile is being used on the home page. Please remove it from there and try again",
        showConfirmButton: false,
      })
    } else {
      const bodyPro = {
        id: data.row.id,
        is_active: true
      }

      if (data.row.is_active) {
        Swal.fire({
          title: "You want to Inactivate this User?",
          text: "",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Deactivated!", "User has been Inactivated.", "error");
            setisActive(false);
            bodyPro.is_active = false
            setUser(bodyPro)
            // UserUpdatehandler(bodyPro)
            getChangedStatus(bodyPro)
          }
        });
      } else {

        Swal.fire({
          title: "You want to Activate this User?",
          text: "",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Activated!", "User has been Activated.", "success");
            bodyPro.is_active = true
            setisActive(true);
            setUser(bodyPro)
            // UserUpdatehandler(bodyPro)
            getChangedStatus(bodyPro)
          }
        });

      }
    }


  }

  const seeProfile = (data) => {
    if ((data.row.is_import === "Imported") || data.row.is_active === true ) {
      const win = window.open(`${data.row.custom_link_with_domain}`, "_blank");
      win.focus();
    }
  }

  const visibilityViewUser = (data) => {
    history.push(`/user/login-history/${data.row.user_id}/firstname=${data.row.first_name}&lastname=${data.row.last_name}`)
  }

  const UserUpdatehandler = (bodyPro = null) => {
    dispatch(putHttp2(`${SERVER_URI}api/profile/update/`, bodyPro))
      .then((res) => {
        let object = res.data.data;
        const params = {
          id: object.id,
          first_name: object.first_name,
          last_name: object.last_name,
          email: object.user.email,
          custom_profile_link: object.is_profile_complete === true || object.is_import !== false ? object.custom_profile_link : object.custom_profile_link,
          is_active: object.is_active,
          deleted_at: moment(object.deleted_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
          created_at: moment(object.created_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
          updated_at: object.updated_at && object.is_profile_complete !== false ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY") : 'None',
          profile_complete_date: object.profile_complete_date !== null ? object.profile_complete_date : "Completed Date Not Available",
          views: object.views,
          role: object.role,
          user_id: object.user.id,
          custom_link_with_domain: object.custom_link_with_domain,
          is_home_page: object.is_home_page,
          is_profile_complete: object.is_profile_complete,
          home_page_rank: object.home_page_rank,
          is_import: object.is_import === true ? "Imported" : object.is_test_user === true ? "Test" : "Default",
          // is_Active:object.is_active === true ? "Active" : "InActive"
          is_Active: object.status,
          is_test_user: object.is_test_user === true ? "Test" : "Default",
          
          
        }
        let existingItem = [...rows].findIndex(item => item.id === res.data.data.id);
        let temp_array = [...rows];
        temp_array[existingItem] = params
        setRows(temp_array)

        let existingItems = dataRows.findIndex(item => item.id === res.data.data.id);
        let temp_arrays = [...dataRows];
        temp_arrays[existingItems] = params
        setDataRows(temp_arrays)
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "User updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        onClickAddModalClose()
      }).catch((err) =>
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Given Data was invalid!",
          showConfirmButton: false,
          timer: 1500,
        })
      );
  };


  const UserUpdatehandlerSubmit = (e) => {
    e.preventDefault();
     if(user.custom_profile_link !== "") {
    setError("")
    let bodyProo = {}
    if (user.password !== "") {
      bodyProo.id = user.id;
      bodyProo.role = user.role;
      bodyProo.custom_profile_link = user.custom_profile_link?.trim()?.toLocaleLowerCase();
      bodyProo.is_test_user = isTestUserFlag;
      if(checkbox === true){
        bodyProo.is_import = true;
        bodyProo.is_profile_complete = false
      } else if (isProfileCompletedFlag === true){
        bodyProo.is_profile_complete = true
      }
      
    } else {
      bodyProo.id = user.id;
      bodyProo.role = user.role;
      bodyProo.custom_profile_link = user.custom_profile_link?.trim()?.toLocaleLowerCase();
      bodyProo.is_test_user = isTestUserFlag;
      if(checkbox === true){
        bodyProo.is_import = true;
        bodyProo.is_profile_complete = false
      } else if (isProfileCompletedFlag === true){
        bodyProo.is_profile_complete = true
      }
    }
    if (selected.value === "No") {
      onClickAddModalClose(e)
      removeProfileHomePage()
    } else {
      if (isProfileCompleted === true) {
        userHomePage(selected.value, user.id)
      }
    }
    dispatch(putHttp2(`${SERVER_URI}api/profile/update/`, bodyProo))
      .then((res) => {
        let object = res.data.data;
        if (Object.keys(res.data.data).length > 0) {

          const params = {
            id: object.id,
            first_name: object.first_name,
            last_name: object.last_name,
            email: object.user.email,
            custom_profile_link: object.is_profile_complete === true || object.is_import !== false ? object.custom_profile_link : "Profile not completed yet.",
            is_active: object.is_active,
            deleted_at: moment(object.deleted_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
            created_at: moment(object.created_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
            updated_at: object.updated_at && object.is_profile_complete !== false ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY") : 'None',
            profile_complete_date: object.profile_complete_date !== null ? object.profile_complete_date : "Completed Date Not Available",
            views: object.views,
            role: object.role,
            user_id: object.user.id,
            custom_link_with_domain: object.custom_link_with_domain,
            is_home_page: object.is_home_page,
            is_profile_complete: object.is_profile_complete,
            home_page_rank: object.home_page_rank,
            is_import: object.is_import === true ? "Imported" : object.is_test_user === true ? "Test" : object.is_import === null ? "Reactivated" :  "Default",
            is_Active: object.status,
            is_test_user: object.is_test_user,
            custom_profile_link_user: object.custom_profile_link,
            status: object.status,
            custom_link_admin_view : object.is_profile_complete === true || object.is_import !== false  ?    BASE_URI_WEB + "sp/" + object.custom_profile_link : object.status === "In Active" || object.status === "Verified" || object.status === "Started Onboarding" ?  "Profile not completed yet."  : "Profile not completed yet."

          }
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User updated successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          let existingItem = [...rows].findIndex(item => item.id === res.data.data.id);
          let temp_array = [...rows];
          temp_array[existingItem] = params
          setRows(temp_array)

          let existingItems = dataRows.findIndex(item => item.id === res.data.data.id);
          let temp_arrays = [...dataRows];
          temp_arrays[existingItems] = params
          setDataRows(temp_arrays);
          onClickAddModalClose(e);
          // if(isTestUserFlag){
          //   getChangedStatus()
          // }
          setisTestUserFlag(object.is_test_user)

        }



      }).catch((err) => {
        onClickAddModalClose(e)
      }
        // Swal.fire({
        //   position: "top-end",
        //   icon: "error",
        //   title: "Given Data was invalid!",
        //   showConfirmButton: false,
        //   timer: 1500,
        // })

      );
     } else {
      setError("Profile Link can't be empty")
     }

  };


  const restoreUser = (e,data) => {
    setLoading(true)
    dispatch(removeHttp2(`${SERVER_URI}api/restore/user/${data.id}/`))
      .then((res) => {
        setLoading(false)
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "User Restored successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        let lists = [...rows].filter(x => {
          return x.id !== data.id;
        })

        let listsRow = [...dataRows].filter(x => {
          return x.id !== data.id;
        })

        setRows(lists)
        setDataRows(listsRow)
      }).catch((err) => {
        onClickAddModalClose(e)
      }
      );
  }

  const getChangedStatus = (params) => {
    dispatch(putHttp2(`${SERVER_URI}api/change/status/`,params))
    .then((res) => {
      let object = res.data.data;
      const params = {
        id: object.id,
        first_name: object.first_name,
        last_name: object.last_name,
        email: object.user.email,
        custom_profile_link: object.is_profile_complete === true || object.is_import !== false ? object.custom_profile_link : object.custom_profile_link,
        is_active: object.is_active,
        deleted_at: moment(object.deleted_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
        created_at: moment(object.created_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY"),
        updated_at: object.updated_at && object.is_profile_complete !== false ? moment(object.updated_at, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY") : 'None',
        profile_complete_date: object.profile_complete_date !== null ? object.profile_complete_date : "Completed Date Not Available",
        views: object.views,
        role: object.role,
        user_id: object.user.id,
        custom_link_with_domain: object.custom_link_with_domain,
        is_home_page: object.is_home_page,
        is_profile_complete: object.is_profile_complete,
        home_page_rank: object.home_page_rank,
        is_import: object.is_import === true ? "Imported" : object.is_test_user === true ? "Test" : object.is_import === null ? "Reactivated" :  "Default",
        // is_Active:object.is_active === true ? "Active" : "InActive"        // is_Active:object.is_active === true ? "Active" : "InActive"
        is_Active: object.status,
        is_test_user: object.is_test_user,
        custom_profile_link_user: object.custom_profile_link,
        status: object.status,
        custom_link_admin_view : object.is_profile_complete === true || object.is_import !== false  ?    BASE_URI_WEB + "sp/" + object.custom_profile_link : object.status === "In Active" || object.status === "Verified" || object.status === "Started Onboarding" ?  "Profile not completed yet."  : "Profile not completed yet."

      }
      let existingItem = [...rows].findIndex(item => item.id === res.data.data.id);
      let temp_array = [...rows];
      temp_array[existingItem] = params
      setRows(temp_array)

      let existingItems = dataRows.findIndex(item => item.id === res.data.data.id);
      let temp_arrays = [...dataRows];
      temp_arrays[existingItems] = params
      setDataRows(temp_arrays)

    }).catch((err) => {

    })
  }

  const isImportChange = (event) => {
    setCheckbox(event.target.checked)
  }

  const isTestUserStatus = (event) => {
    setisTestUserFlag(event.target.checked)
  }
  
  const isProfileCompletedStatus = (event) => {
    setisProfileCompletedFlag(event.target.checked)
  }

  const onChangeUserFields = (event) => {
    const { name, value } = event.target;
    if (name === "custom_profile_link") {
      const profileUrl = {
        profile_custom_url: value.toLocaleLowerCase(),
        id: user.id
      }
      dispatch(postHttp2(`${SERVER_URI}api/check/profile/url/`, profileUrl))
        .then((res) => {
          if (res.data.message === false) {
            setDisabled(true)
            setError("Profile Link Already Exist")
          } else {
            setDisabled(false)
            setError("")

          }

        }).catch((err) => {

        }

        );
    }
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const removeProfileHomePage = () => {
    dispatch(removeHttp(`${SERVER_URI}api/home_page_profile/${isProfileHomePage}`)).then(() => {
      onClickAddModalClose();
      users()
      document.getElementById("kt_notes_panel").classList.remove("offcanvas-on")
    })
  }



  const onChangeRole = (e) => {
    setUser((prevState) => ({ ...prevState, role: e.target.value }));
  }
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = dataRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(JSON.stringify(row[field]));
      });
    });
    setRows(filteredRows);
  };


  const  handleColumnVisibilityChange = (col) => {
    setColumnVisibilityModel(col)
}

  const Datatable = () => {
    return (
      <div className="">
        <div style={{ width: '100%', maxWidth: "100%" }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'desc' }],
                  },
                }}
                rows={rows}
                columns={col}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                pageSize={pageSize}
                pagination
                components={{ Toolbar: QuickSearchToolbar }}
                getRowClassName={(params) => `MuiDataGrid-row-${params.row.home_page_rank}`}
                disableSelectionOnClick
                // disableColumnMenu
                // disableColumnFilter
                // disableColumnSelector
                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    handleColumnVisibilityChange(newModel)
                                  }
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch('')
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

    )
  }
  const Spinner = () => {
    return (
      <div className="custom-loader">
        <div className="pre-loader">
          <img
            className="img-fluid"
            src={Loader}
            alt="loading"
          />
        </div>
      </div>
    )
  }
  return (
    <Fragment>

      <Helmet>
        <title>Subscriptions Plans Management | HelloCustom</title>
      </Helmet>

      <div
        id="tc_body"
        className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
      >
        {/*begin::Header Mobile*/}
        <MobileHeader />
        {/*end::Header Mobile*/}
        {/*begin::Main*/}
        <div className="d-flex flex-column flex-root">
          {/*begin::Page*/}
          <div className="d-flex flex-row flex-column-fluid page">
            <MainHeaderWithSidebar />
            {/*end::Aside*/}
            {/*begin::Wrapper*/}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="tc_wrapper"
            >
              {/*begin::Header*/}
              <FixedHeader />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="tc_content"
              >
                {/*begin::Subheader*/}
                {/* <div className="subheader py-2 py-lg-6 subheader-solid">
                  <div className="container-fluid">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb bg-white mb-0 px-0 py-2">
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <a href="/dashboard">Dashboard</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div> */}
                {/*end::Subheader*/}
                {/*begin::Entry*/}
                <div className="d-flex flex-column-fluid" id="asdddd">
                  {/*begin::Container*/}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="card card-custom gutter-b bg-transparent shadow-none border-0">
                              <div className="card-header align-items-center  border-bottom-dark p-0">
                                <div className="card-title mb-0">
                                  <h3 className="card-label mb-0 font-weight-bold text-body">
                                    {history.location.pathname === "/users-deleted" ? "Deleted Users" : "Subscriptions Plans Management"}
                                  </h3>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 ">
                            <div className="card card-custom gutter-b bg-white border-0">
                              <div className="card-body">
                                {isLoading === true ? <Spinner></Spinner> : Datatable()}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*end::Footer*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}

        <div
          id="kt_notes_panel"
          className="offcanvas offcanvas-right kt-color-panel p-5"
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 className="font-size-h4 font-weight-bold m-0">
              Edit User
            </h4>
            <a
              href="javascript:void(0);"
              className="btn btn-sm btn-icon btn-light btn-hover-primary"
              id="kt_notes_panel_close"
              onClick={(e) => onClickAddModalClose()}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 16 16"
                className="bi bi-x"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </a>
          </div>
          <form id="myform">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="text-dark" htmlFor="exampleInputEmail1">
                    Role
                  </label>

                  <select
                    className="arabic-select w-100 mb-3"
                    name="role"
                    onChange={(e) => onChangeRole(e)}
                  >
                    <option value="" disabled selected>
                      Select Role
                    </option>
                    <option
                      value="U"
                      selected={user.role === "U" ? true : false}
                    >
                      User
                    </option>
                    <option
                      value="A"
                      selected={user.role === "A" ? true : false}
                    >
                      Admin
                    </option>
                  </select>
                </div>
                {
                  socialLoginHide !== true ? <div className="form-group">
                
                  <label className="text-dark cursor-pointer"> 
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={checkbox}
                    onChange={(e) => isImportChange(e)}
                  />
                  Make this Account Imported
                  </label>
                 
                </div> : ""
                }
                
                <div className="form-group">
                
                  <label className="text-dark cursor-pointer"> 
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={isProfileCompletedFlag}
                    onChange={(e) => isProfileCompletedStatus(e)}
                  />
                  Make this Profile Completed
                  </label>
               
              </div>
              <div className="form-group">
                  <label className="text-dark cursor-pointer"> 
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={isTestUserFlag}
                    onChange={(e) => isTestUserStatus(e)}
                  />
                  Test User
                  </label>
               
              </div>
                <div className="form-group">
                  <label className="text-dark">Profile Link</label>
                  <DelayInput
                    type="text"
                    className="form-control"
                    name="custom_profile_link"
                    value={user.custom_profile_link?.toLocaleLowerCase()}
                    onChange={(e) => onChangeUserFields(e)}
                    placeholder="Profile Link"
                  />
                  <p style={{ color: "red" }}>{error}</p>
                </div>

                <div className="d-flex">
                  {
                    isActiveProfile === true && isProfileCompleted === true ?
                      <>
                        <label htmlFor="" className="mt-3 mr-4"> Show on Home Page ?</label>
                        <Select
                          className="custom-form-control mb-2 ml-3"
                          placeholder="Select Ranks"
                          name="ranks"
                          selected={selected}
                          onChange={(e) => handleChange(e)}
                          options={isHomePageRank === null ? ranks : ranksNo}
                          value={selected}
                        />

                      </>
                      :
                      ""
                  }

                </div>
                <p style={{ color: "red" }}>{showErrorMessage}</p>
              </div>


              {/* </Tab.Container> */}
              {/* </div> */}
            </div>
            {history.location.pathname === "/users-deleted" ? "" :

              <button
                type="submit"
                disabled={disabled}
                className="btn btn-primary"
                onClick={

                  (e) => UserUpdatehandlerSubmit(e)

                }
              >
                Edit User
              </button>
            }

          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(SubscriptionsPlans);
