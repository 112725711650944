const DEV_ENV = {
  API_URL: "https://prdapi.zimzee.com/api",
  s3BucketUrl: "https://d2jgmcy51w050u.cloudfront.net",
};

const PROD_ENV = {
  API_URL: "https://prdapi.zimzee.com/api",
  // s3BucketUrl: "https://d2ca3by5mmwys8.cloudfront.net",
};

const checkEnv = () => {
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("dev")
  ) {
    return DEV_ENV;
  } else {
    return PROD_ENV;
  }
};
const config = checkEnv();
export default config;
