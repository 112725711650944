import React, { Fragment, useEffect } from "react";

import $ from "jquery";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import {  useDispatch } from "react-redux";
import { TOGGLE_SIDEBAR } from "../../../actions/types";
import logo from '../../../assets/images/zimzee-logo.svg'
import { useHistory } from "react-router-dom";
const MobileHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const OnClickOne = (e) => {
    e.preventDefault();
    $(".aside-overlay").addClass("active");
    $("#tc_aside").toggleClass("aside-on");
    dispatch({
      type: TOGGLE_SIDEBAR,
    });
  };

  const onClickTwo = (e) => {
    e.preventDefault();
    $("body").toggleClass("topbar-mobile-on");
  };

  const onClickOverlay = (e) => {
    e.preventDefault();
    $("#tc_aside").removeClass("aside-on");
    $(".aside-overlay").removeClass("active");
    dispatch({
      type: TOGGLE_SIDEBAR,
    });
  };
  const onClickback = () => {
   
    localStorage.removeItem("user");
    history.push('/')
  
    
  };

  return (
    <Fragment>
      <div
        id="tc_header_mobile"
        className="header-mobile align-items-center header-mobile-fixed"
      >
        <a className="brand-logo" href="/">
            <span className="brand-text">
              <img
                style={{ height: 25 }}
                alt="Logo"
                src={logo}
              />
            </span>
          </a>
        
        <div className="d-flex align-items-center">
          <button
            className="btn p-0"
            id="tc_aside_mobile_toggle"
            onClick={(e) => OnClickOne(e)}
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 16 16"
              className="bi bi-justify-right"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <button
            className="btn p-0 ml-2"
            id="tc_header_mobile_topbar_toggle"
            onClick={(e) => onClickTwo(e)}
          >
            <span className="svg-icon svg-icon-xl">
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 16 16"
                className="bi bi-person-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="aside" id="tc_aside" style={{ display: "none" }}>
        <ProSidebar breakPoint="md">
          <SidebarHeader>
            <div className="brand flex-column-auto" id="tc_brand">
              {/*begin::Logo*/}
              <a className="brand-logo" href="/">
                  <img
                    className="brand-image"
                    style={{ height: 25 }}
                    alt="Logo"
                    src={logo}
                  />
                  <span className="brand-text">
                    <img
                      style={{ height: 25 }}
                      alt="Logo"
                      src={logo}
                    />
                  </span>
                </a>
              {/*end::Logo*/}
            </div>
          </SidebarHeader>

          <SidebarContent className="scroll-maunal">
            <Menu>
              {/* <MenuItem
                icon={
                  <span className="svg-icon nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                      <polyline points="9 22 9 12 15 12 15 22" />
                    </svg>
                  </span>
                }
              >
                <a href="/">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Dashboard</span>
                  </a>
                </a>
              </MenuItem> */}
              <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-users font-size-h4" />
                  </span>
                }
              >
                <a href="/users">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Users</span>
                  </a>
                </a>
              </MenuItem>
              <MenuItem
                icon={
                  <span className="svg-icon nav-icon">
                   <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 16 16"
                        className="bi bi-person-fill"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                        />
                      </svg>
                  </span>
                }
                onClick={() => onClickback()}
              >
                <a href={`javascript:void(0)`}>
                  <a className="nav-item active">
                    <span className="nav-text fontt">Logout</span>
                  </a>
                </a>
              </MenuItem>
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-chart-line font-size-h4" />
                  </span>
                }
              >
                <a href="/sections">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Categories</span>
                  </a>
                </a>
              </MenuItem> */}
              {/* <MenuItem
                 icon={
                  <span className="svg-icon nav-icon">
                    <i className="fas fa-clipboard-check font-size-h4" />
                  </span>
                }
              >
                <a href="/user-history">
                  <a className="nav-item active">
                    <span className="nav-text fontt">Users Login History</span>
                  </a>
                </a>
              </MenuItem> */}
              {/* <MenuItem
                  icon={
                    <span className="svg-icon nav-icon">
                      <i className="fas fa-user-friends font-size-h4" />
                    </span>
                  }
              >
                <a href="/user-views">
                  <a className="nav-item active">
                    <span className="nav-text fontt">User Views</span>
                  </a>
                </a>
              </MenuItem> */}
            
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>

      <div className="aside-overlay" onClick={(e) => onClickOverlay(e)} />
    </Fragment>
  );
};

export default MobileHeader;
