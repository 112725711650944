import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Routes from "./routing/Routes";
import "./assets/scss/style.scss";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer, toast } from "react-toastify";
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <Router>
          {/* <Layout> */}

          <Route component={Routes} />

          {/* </Layout> */}
        </Router>
      </Provider>
    );
  }
}
export default App;
