import { Link } from "react-router-dom";
import Image404 from "../../assets/images/404-image.svg";
import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    // <div className="static-page-wrapper">
    //     <div className="not-found-wrapper">
    //         <div className="container">
    //             <div className="content-404">
    //                 <img src={Image404} alt="404" />
    //                 <p>Sorry, we couldn’t find the page <br /> you are looking for.</p>
    //                 <Link to = {"/dashboard"}className="site-btn-2 btn-2-large">Go to Homepage</Link>

    //             </div>
    //         </div>
    //     </div>
    // </div>
    <>
      <Helmet>
        <title>Error | Page Not Found</title>
      </Helmet>
      <div className="auth-main-wrapper-404">
        <div className="not-found-wrapper">
          <div className="logo-here">
            <img src={Image404} alt="logo" />
          </div>
          <div className="not-found-text">
            <h1>Not found (404)</h1>
            <h3>Sorry, the page you are looking for does not exist.</h3>
            <Link to={"/"} className="site-btn">
              Go back
            </Link>
            {/* <h4>Please visit our <a href="#">Help Center</a> for more information. </h4> */}
            {/* <p>Error code: [1234567890]</p> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default NotFound;
