import config from "../../utils/config";

export const SERVER_URI = config.API_URL;
// export const BASE_URI = 'https://dev.sparrows.co.s3-website-us-east-1.amazonaws.com/';
// export const BASE_URI_WEB = 'https://v2.sparrows.co/';
export const BASE_URI_WEB = "https://www.sparrows.co/";
// export const BASE_URI_WEB = 'http://localhost:3000/';
export const S3_BUCKET_URI = "https://sparrows-videos.s3.amazonaws.com/";

export const BASE_URI = "https://admin.v2.sparrows.co/";
// export const BASE_URI = 'https://admin.sparrows.co/';
