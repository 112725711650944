import { ContactSupportOutlined } from "@material-ui/icons";
import {

} from "../actions/types";



const initialState = {
 
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case HYDRATE:
    //         // Attention! This will overwrite client state! Real apps should use proper reconciliation.
    //         return {...state, ...payload};
    
    default:
      return state;
  }
}
