import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Users from "../pages/users";
import VerifyAdmin from "../pages/verify";
import login from "../pages/login/login";
import AccessDeni from "../pages/accessDenied";
// for show alert
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
// import Tags from "../pages/tags";
// import Groups from "../pages/groups";
// import Categories from "../pages/category";
import approvedOrders from "../pages/approvedOrders";
// import UserHistory from "../pages/userHistory";
// import UserViews from "../pages/userView";
// import Examples from "../pages/examples";

// import UserDetailHistory from "../pages/userHistory/userDetail";

import NotFound from "../pages/NotFound/index";
// import Icons from "../pages/icons";
import subscriptionsPlans from "../pages/subscriptionsPlans";
// import UserNew from "../pages/UserNew";
import ApprovedOrderNew from "../pages/ApprovedOrderNew";

// set alert time and position
const options = {
  timeout: 2000,
  position: positions.TOP_RIGHT,
};

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
        )
      }
    />
  );
};

const ProtectedRouteAfterAuthenticate = ({
  component: Component,
  authed,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <Redirect
            to={{ pathname: "/users", state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Provider template={AlertTemplate} {...options}>
      <Switch>
        <ProtectedRouteAfterAuthenticate exact path="/" component={login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/users" component={Users} />
        {/* <PrivateRoute exact path='/user-new' component={UserNew} />  */}
        <PrivateRoute exact path="/order-new" component={ApprovedOrderNew} />
        <PrivateRoute
          exact
          path="/approved-orders"
          component={approvedOrders}
        />
        <PrivateRoute
          exact
          path="/subscriptions-plans"
          component={subscriptionsPlans}
        />
        {/* <PrivateRoute exact path="/users-deleted" component={Users} />
        <PrivateRoute exact path="/icons" component={Icons} />
        <PrivateRoute exact path="/tags" component={Tags} />
        <PrivateRoute exact path="/groups" component={Groups} />
        <PrivateRoute exact path="/sections" component={Categories} />
        <PrivateRoute exact path="/groups" component={Groups} />

        <PrivateRoute exact path="/a-little-about-me" component={Examples} />
        <PrivateRoute exact path="/what-im-up-to" component={Examples} /> */}
        {/* <PrivateRoute
          exact
          path="/a-surprising-fact-about-me"
          component={Examples}
        />

        <PrivateRoute exact path="/user-history" component={UserHistory} />
        <PrivateRoute exact path="/user-views" component={UserViews} />
        <PrivateRoute
          exact
          path="/user/login-history/:id/firstname=:firstname&lastname=:lastname"
          component={UserDetailHistory}
        /> */}
        <Route exact path="/verify/:token" component={VerifyAdmin} />
        <Route exact path="/acess-denied" component={AccessDeni} />
        <Route path="*" render={(props) => <NotFound {...props} />} />
      </Switch>
    </Provider>
  );
};

export default Routes;
