import React from 'react'
import { Oval } from "react-loader-spinner";
const FullScreenLoader = () => {
  return (
    
         <div className="auth-main-wrapper">
       <Oval
          height={80}
          width={80}
          color={"#ee7900"}
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ee7900"
          strokeWidth={10}
          strokeWidthSecondary={10}
        />
        </div>
   
  )
}

export default FullScreenLoader
