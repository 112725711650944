import React, { Fragment, useEffect, useState } from "react";
import MobileHeader from "../../layouts/Header/MobileHeader";
import MainHeaderWithSidebar from "../../layouts/Header/MainHeader";
import { useHistory } from "react-router-dom";
import FixedHeader from "../../layouts/Header/FixedHeader";
import axios from "axios";
import { getHttp2 } from "../../actions/config";
import { SERVER_URI } from "../../components/utils/config";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import request from "../../utils/request";
import config from "../../utils/config";
const Dashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    request
      .get(`${config.API_URL}/api/v1/admin/dashBoard`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-Access-Token": user.token,
        },
      })
      .then((response) => {
        setData(response.data.data);

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin" : "*"
    //   }
    // };
    // axios.get(`https://app.hellocustom.io/api/v1/admin/dashBoard`,config)
    // .then(res => {
    //   console.log(res)
    //   if(res.status === 200){
    //    console.log(res)

    //   }

    // })
    // .catch( err => {
    //   console.log(err)

    // })
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Dashboard | HelloCustom</title>
      </Helmet>

      <body
        id="tc_body"
        className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
      >
        {/*begin::Header Mobile*/}
        <MobileHeader />
        {/*end::Header Mobile*/}
        {/*begin::Main*/}
        <div className="d-flex flex-column flex-root">
          {/*begin::Page*/}
          <div className="d-flex flex-row flex-column-fluid page">
            <MainHeaderWithSidebar />

            {/*end::Aside*/}
            {/*begin::Wrapper*/}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="tc_wrapper"
            >
              {/*begin::Header*/}
              <FixedHeader />
              {/*end::Header*/}
              {/*begin::Content*/}
              <div
                className="content d-flex flex-column flex-column-fluid dashboard-wrapper"
                id="tc_content"
              >
                {/*begin::Subheader*/}
                {/* <div className="subheader py-2 py-lg-6 subheader-solid">
              <div className="container-fluid">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-white mb-0 px-0 py-2">
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> */}
                {/*end::Subheader*/}
                {/*begin::Entry*/}

                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-6 col-xl-4">
                            <div className="card-heading">
                              <h2>Plans</h2>
                            </div>
                            {data.planStats &&
                              data.planStats.map((item, index) => (
                                <div
                                  className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-primary"
                                  key={index}
                                >
                                  <div className="card-body">
                                    <h3 className="text-body font-weight-bold">
                                      {item.name}
                                    </h3>
                                    <div className="mt-3">
                                      <div className="d-flex align-items-center">
                                        <span className="text-dark font-weight-bold font-size-h1 mr-3">
                                          <span className="counter">
                                            {/* {data.totalUser} */}
                                            {item.value}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-6 col-xl-4">
                            <div className="card-heading">
                              <h2>Graphics</h2>
                            </div>
                            {data.graphicsStats &&
                              data.graphicsStats.map((item, index) => (
                                <div
                                  className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-secondary"
                                  key={index}
                                >
                                  <div className="card-body">
                                    <h3 className="text-body font-weight-bold">
                                      {item.name}
                                    </h3>
                                    <div className="mt-3">
                                      <div className="d-flex align-items-center">
                                        <span className="text-dark font-weight-bold font-size-h1 mr-3">
                                          <span className="counter">
                                            {/* {data.totalActiveUsers} */}
                                            {item.value}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-lg-6 col-xl-4">
                            <div className="card-heading">
                              <h2>Revenue</h2>
                            </div>
                            {data.revenuesStats &&
                              data.revenuesStats.map((item, index) => (
                                <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
                                  <div className="card-body">
                                    <h3 className="text-body font-weight-bold">
                                      {item.name}
                                    </h3>
                                    <div className="mt-3">
                                      <div className="d-flex align-items-center">
                                        <span className="text-dark font-weight-bold font-size-h1 mr-3">
                                          <span
                                            className="counter"
                                            // data-target={1000}
                                          >
                                            {/* {data.totalInActiveUsers} */}$
                                            {item.value}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div className="col-lg-6 col-xl-4">
                        <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
                          <div className="card-body">
                            <h3 className="text-body font-weight-bold">
                              Subscription Plans
                            </h3>
                            <div className="mt-3">
                              <div className="d-flex align-items-center">
                                <span className="text-dark font-weight-bold font-size-h1 mr-3">
                                  <span
                                    className="counter"
                                  >
                                   {data.totalPlans}
                                  </span>
                                </span>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div> */}

                          {/* <div className="col-lg-6 col-xl-4">
                        <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
                          <div className="card-body">
                            <h3 className="text-body font-weight-bold">
                              Complete Profiles
                            </h3>
                            <div className="mt-3">
                              <div className="d-flex align-items-center">
                                <span className="text-dark font-weight-bold font-size-h1 mr-3">
                                  <span
                                    className="counter"
                                    // data-target={1000}
                                  >
                                  {data.completed_profiles}
                                  </span>
                                </span>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*end::Footer*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::Main*/}
      </body>
    </Fragment>
  );
  // isLoading ? (
  //   <div className="se-pre-con"></div>
  // ) : (
  //   <Fragment>
  //     <Helmet>
  //       <title>Dashboard | HelloCustom</title>
  //     </Helmet>

  //     <body
  //       id="tc_body"
  //       className="header-fixed header-mobile-fixed subheader-enabled aside-enabled aside-fixed"
  //     >
  //       {/*begin::Header Mobile*/}
  //       <MobileHeader />
  //       {/*end::Header Mobile*/}
  //       {/*begin::Main*/}
  //       <div className="d-flex flex-column flex-root">
  //         {/*begin::Page*/}
  //         <div className="d-flex flex-row flex-column-fluid page">
  //           <MainHeaderWithSidebar />

  //           {/*end::Aside*/}
  //           {/*begin::Wrapper*/}
  //           <div
  //             className="d-flex flex-column flex-row-fluid wrapper"
  //             id="tc_wrapper"
  //           >
  //             {/*begin::Header*/}
  //             <FixedHeader />
  //             {/*end::Header*/}
  //             {/*begin::Content*/}
  //             <div
  //               className="content d-flex flex-column flex-column-fluid dashboard-wrapper"
  //               id="tc_content"
  //             >
  //               {/*begin::Subheader*/}
  //               {/* <div className="subheader py-2 py-lg-6 subheader-solid">
  //                 <div className="container-fluid">
  //                   <nav aria-label="breadcrumb">
  //                     <ol className="breadcrumb bg-white mb-0 px-0 py-2">
  //                       <li
  //                         className="breadcrumb-item active"
  //                         aria-current="page"
  //                       >
  //                         Dashboard
  //                       </li>
  //                     </ol>
  //                   </nav>
  //                 </div>
  //               </div> */}
  //               {/*end::Subheader*/}
  //               {/*begin::Entry*/}

  //               <div className="d-flex flex-column-fluid">
  //                 {/*begin::Container*/}

  //                 <div className="container-fluid">
  //                   <div className="row">
  //                     <div className="col-12">
  //                       <div className="row">
  //                         <div className="col-lg-6 col-xl-4">
  //                           <div className="card-heading">
  //                             <h2>Plans</h2>
  //                           </div>
  //                           {data.planStats &&
  //                             data.planStats.map((item, index) => (
  //                               <div
  //                                 className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-primary"
  //                                 key={index}
  //                               >
  //                                 <div className="card-body">
  //                                   <h3 className="text-body font-weight-bold">
  //                                     {item.name}
  //                                   </h3>
  //                                   <div className="mt-3">
  //                                     <div className="d-flex align-items-center">
  //                                       <span className="text-dark font-weight-bold font-size-h1 mr-3">
  //                                         <span className="counter">
  //                                           {/* {data.totalUser} */}
  //                                           {item.value}
  //                                         </span>
  //                                       </span>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             ))}
  //                         </div>
  //                         <div className="col-lg-6 col-xl-4">
  //                           <div className="card-heading">
  //                             <h2>Graphics</h2>
  //                           </div>
  //                           {data.graphicsStats &&
  //                             data.graphicsStats.map((item, index) => (
  //                               <div
  //                                 className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle theme-circle-secondary"
  //                                 key={index}
  //                               >
  //                                 <div className="card-body">
  //                                   <h3 className="text-body font-weight-bold">
  //                                     {item.name}
  //                                   </h3>
  //                                   <div className="mt-3">
  //                                     <div className="d-flex align-items-center">
  //                                       <span className="text-dark font-weight-bold font-size-h1 mr-3">
  //                                         <span className="counter">
  //                                           {/* {data.totalActiveUsers} */}
  //                                           {item.value}
  //                                         </span>
  //                                       </span>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             ))}
  //                         </div>
  //                         <div className="col-lg-6 col-xl-4">
  //                           <div className="card-heading">
  //                             <h2>Revenue</h2>
  //                           </div>
  //                           {data.revenuesStats &&
  //                             data.revenuesStats.map((item, index) => (
  //                               <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
  //                                 <div className="card-body">
  //                                   <h3 className="text-body font-weight-bold">
  //                                     {item.name}
  //                                   </h3>
  //                                   <div className="mt-3">
  //                                     <div className="d-flex align-items-center">
  //                                       <span className="text-dark font-weight-bold font-size-h1 mr-3">
  //                                         <span
  //                                           className="counter"
  //                                           // data-target={1000}
  //                                         >
  //                                           {/* {data.totalInActiveUsers} */}$
  //                                           {item.value}
  //                                         </span>
  //                                       </span>
  //                                     </div>
  //                                   </div>
  //                                 </div>
  //                               </div>
  //                             ))}
  //                         </div>
  //                         {/* <div className="col-lg-6 col-xl-4">
  //                           <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
  //                             <div className="card-body">
  //                               <h3 className="text-body font-weight-bold">
  //                                 Subscription Plans
  //                               </h3>
  //                               <div className="mt-3">
  //                                 <div className="d-flex align-items-center">
  //                                   <span className="text-dark font-weight-bold font-size-h1 mr-3">
  //                                     <span
  //                                       className="counter"
  //                                     >
  //                                      {data.totalPlans}
  //                                     </span>
  //                                   </span>
  //                                 </div>

  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div> */}

  //                         {/* <div className="col-lg-6 col-xl-4">
  //                           <div className="card card-custom gutter-b bg-white border-0 theme-circle theme-circle-success">
  //                             <div className="card-body">
  //                               <h3 className="text-body font-weight-bold">
  //                                 Complete Profiles
  //                               </h3>
  //                               <div className="mt-3">
  //                                 <div className="d-flex align-items-center">
  //                                   <span className="text-dark font-weight-bold font-size-h1 mr-3">
  //                                     <span
  //                                       className="counter"
  //                                       // data-target={1000}
  //                                     >
  //                                     {data.completed_profiles}
  //                                     </span>
  //                                   </span>
  //                                 </div>

  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div> */}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             {/*end::Footer*/}
  //           </div>
  //           {/*end::Wrapper*/}
  //         </div>
  //         {/*end::Page*/}
  //       </div>
  //       {/*end::Main*/}
  //     </body>
  //   </Fragment>
  // );
};

export default Dashboard;
